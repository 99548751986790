import store from "../users/store";

const moduleRoute = {
  path: "/settings",
  component: () =>
    import(
      /* webpackChunkName: "settings-module", webpackPrefetch: true */ "./Module.vue"
    ),
  children: [
    {
      path: "/company",
      component: () =>
        import(
          /* webpackChunkName: "company", webpackPrefetch: true */ "./views/Company.vue"
        ),
    },

    {
      path: "/billingAndSubscription",
      component: () =>
        import(
          /* webpackChunkName: "billingAndSubscription", webpackPrefetch: true */ "./views/BillingAndSubscription.vue"
        ),
    },
    {
      path: "/branches",
      component: () =>
        import(
          /* webpackChunkName: "billingAndSubscription", webpackPrefetch: true */ "./views/Branches.vue"
        ),
    },
    {
      path: "/advanced",
      component: () =>
        import(
          /* webpackChunkName: "billingAndSubscription", webpackPrefetch: true */ "./views/Advanced.vue"
        ),
    },
    {
      path: "/e-invoice",
      component: () =>
        import(
          /* webpackChunkName: "billingAndSubscription", webpackPrefetch: true */ "./views/E-invoice.vue"
        ),
    },
  ],
  beforeEnter: (to, from, next) => {
    if (store.state.token) {
      localStorage.setItem("layout", "dashboard-layout");
      next();
      return;
    }
    localStorage.setItem("layout", "landing-layout");
    next("/login");
  },
};

export default (router) => {
  router.addRoute(moduleRoute);
};
