import Vue from "vue";
import { AXIOS } from "../../../axios";
import { successToast } from "@/helpers/toastifications";

export default {
  namespaced: true,
  state: {
    blogs: [],
    viewloading: true,
    blogById: {},
  },
  getters: {
    blogs: (state) => state.blogs,
    viewloading: (state) => state.viewloading,
    blogById: (state) => state.blogById,
  },
  mutations: {
    setBlogs: (state, data) => {
      state.blogs = data;
    },
    set_blogs_loading: (state, data) => {
      state.viewloading = data;
    },
    setBlogById: (state, data) => {
      state.blogById = data;
    },
  },
  actions: {
    getBlogs: ({ commit }) => {
      AXIOS.get(`api/GetBlogs`)
        .then((res) => {
          commit("setBlogs", res.data);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_blogs_loading", false);
        });
    },

    getBlogsId: ({ commit }, id) => {
      AXIOS.get(`api/GetBlogById/${id}`)
        .then((res) => {
          commit("setBlogById", res.data);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          // commit('set_blogs_loading', false)
        });
    },
  },
  modules: {},
};
