import axios from "axios";
import Vue from "vue";
import { successToast } from "@/helpers/toastifications";
import { AXIOS } from "../../../axios";

export default {
  namespaced: true,
  state: {
    products: [],
    inventoryAccounts: [],
    incomeAccounts: [],
    incomeAccountsForService: [],
    expensesAccountsForService: [],
    inventoryAccountsDefault: "",
    expensesAccounts: [],
    classes: [],
    vendors: [],
    inventory_products: [],
    service_products: [],
    nonInventory_products: [],
    nameExistMsg: "",
    loading: true,
    taxsList: [],
    newTaxsList: [],
    listProducts: [],
    nonInventory_service: [],
    autoSelectedProduct: {},
    autoSelectedClass: {},
    showTable: false,
    productData: [],
    Products: [],
  },
  getters: {
    products: (state) => state.products,
    inventoryAccounts: (state) => state.inventoryAccounts,
    incomeAccounts: (state) => state.incomeAccounts,
    incomeAccountsForService: (state) => state.incomeAccountsForService,
    expensesAccountsForService: (state) =>
      state.expeinvoicensesAccountsForService,
    inventoryAccountsDefault: (state) => state.inventoryAccountsDefault,
    expensesAccounts: (state) => state.expensesAccounts,
    classes: (state) => state.classes,
    vendors: (state) => state.vendors,
    inventory_products: (state) => state.inventory_products,
    service_products: (state) => state.service_products,
    nonInventory_products: (state) => state.nonInventory_products,
    nameExistMsg: (state) => state.nameExistMsg,
    loading: (state) => state.loading,
    taxsList: (state) => state.taxsList,
    newTaxsList: (state) => state.newTaxsList,
    listProducts: (state) => state.listProducts,
    nonInventory_service: (state) => state.nonInventory_service,
    autoSelectedProduct: (state) => state.autoSelectedProduct,
    autoSelectedClass: (state) => state.autoSelectedClass,
    showTable: (state) => state.showTable,
    productData: (state) => state.productData,
    Products: (state) => state.Products,
  },
  mutations: {
    setProducts: (state, data) => {
      state.products = data;
    },

    setinventoryAccounts: (state, data) => {
      state.inventoryAccounts = data;
    },

    setincomeAccounts: (state, data) => {
      state.incomeAccounts = data;
    },
    setincomeAccountsForService: (state, data) => {
      state.incomeAccountsForService = data;
    },
    set_inventoryAccountsDefault: (state, data) => {
      state.inventoryAccountsDefault = data;
    },

    set_expensesAccounts: (state, data) => {
      state.expensesAccounts = data;
    },
    set_expensesAccountsForService: (state, data) => {
      state.expensesAccountsForService = data;
    },

    setClasses: (state, data) => {
      state.classes = data;
    },

    setVendors: (state, data) => {
      state.vendors = data;
    },

    set_inventory_products: (state, data) => {
      state.inventory_products = data;
    },

    set_service_products: (state, data) => {
      state.service_products = data;
    },

    set_nonInventory_products: (state, data) => {
      state.nonInventory_products = data;
    },

    setNameExistMsg: (state, data) => {
      state.nameExistMsg = data;
    },

    set_loading: (state, data) => {
      state.loading = data;
    },

    set_taxsList: (state, data) => {
      state.taxsList = data;
    },

    set_listProducts: (state, data) => {
      state.listProducts = data;
    },

    set_nonInventory_service: (state, data) => {
      state.nonInventory_service = data;
    },

    set_autoSelectedProduct: (state, data) => {
      state.autoSelectedProduct = data;
    },

    set_autoSelectedClass: (state, data) => {
      state.autoSelectedClass = data;
    },

    set_showTable: (state, data) => {
      state.showTable = data;
    },

    set_productData: (state, data) => {
      state.productData = data;
    },
    set_Products: (state, data) => {
      state.Products = data;
    },
  },
  actions: {
    openAddProductPopup() {
      const addProductModal = document.getElementById("addProductModal");
      addProductModal.style.display = "flex";
    },

    closeAddProductPopup() {
      const addProductModal = document.getElementById("addProductModal");
      addProductModal.style.display = "none";
    },
    closeAddClassPopup() {
      const addClassModal = document.getElementById("addClassModal");
      addClassModal.style.display = "none";
    },

    getProductsTableAction: ({ commit }, search) => {
      AXIOS.post("Products/GetProduct", { search: search })
        .then((res) => {
          commit("setProducts", res.data.Products);
          commit("set_showTable", res.data.ShowTable);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_loading", false);
        });
    },

    getProductsData: ({ commit }, search, id) => {
      AXIOS.post(`Products/GetProjectByID/${id}`, { search: search })
        .then((res) => {
          commit("set_productData", res.data.ProductData);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {});
    },

    inventoryAccountsAction: ({ commit }) => {
      AXIOS.get(`Forms/GetAllInventoryCOA`)
        .then((res) => {
          // (res.data);
          commit("setinventoryAccounts", res.data);
        })
        .catch((err) => {
          err.message;
        });
    },

    // incomeAccountsAction: ({ commit }, payload) => {
    //   AXIOS.get(`Forms/GetAllIncomeCOA/${payload.type}`)
    //     .then((res) => {
    //       commit("setincomeAccounts", res.data);
    //     })
    //     .catch((err) => {
    //       (err.message);
    //     });
    // },
    incomeAccountsActionsForProducts: ({ commit }, payload) => {
      AXIOS.get(`Forms/GetAllIncomeCOA/1`)
        .then((res) => {
          commit("setincomeAccounts", res.data);
        })
        .catch((err) => {
          err.message;
        });
    },
    incomeAccountsActionsForService: ({ commit }, payload) => {
      AXIOS.get(`Forms/GetAllIncomeCOA/2`)
        .then((res) => {
          "response serveice is done ", res.data;
          commit("setincomeAccountsForService", res.data);
        })
        .catch((err) => {
          err.message;
        });
    },
    expensesAccountsActionForProduct: ({ commit }, payload) => {
      AXIOS.get(`Forms/GetAllExpensesCOA/1`)
        .then((res) => {
          commit("set_expensesAccounts", res.data);
        })
        .catch((err) => {
          err.message;
        });
    },
    expensesAccountsActionForService: ({ commit }, payload) => {
      AXIOS.get(`Forms/GetAllExpensesCOA/2`)
        .then((res) => {
          commit("set_expensesAccountsForService", res.data);
        })
        .catch((err) => {
          err.message;
        });
    },

    // expensesAccountsAction: ({ commit }, payload) => {
    //   AXIOS.get(`Forms/GetAllExpensesCOA/${payload.type}`)
    //     .then((res) => {
    //       commit("setexpensesAccounts", res.data);
    //     })
    //     .catch((err) => {
    //       (err.message);
    //     });
    // },

    getClassesAction: ({ commit }) => {
      AXIOS.get(`Forms/GetClasses`)
        .then((res) => {
          commit("setClasses", res.data);
        })
        .catch((err) => {
          err.message;
        });
    },

    getVendorsAction: ({ commit }) => {
      AXIOS.post(`Vendor/ListVendor`, {})
        .then((res) => {
          commit("setVendors", res.data.Vendors);
        })
        .catch((err) => {
          err.message;
        });
    },

    getInventoryProductsAction: ({ commit }) => {
      AXIOS.get("Products/GetProductinventory")
          .then((res) => {
            if(res.data.Products.length > 0) {
              commit("set_inventory_products", res.data.Products);
              }else {
                commit("set_inventory_products", []);
              }
          })
          .catch((err) => {
            err.message;
          });
    },
    getOnlyInventoryProductsAction: ({ commit }) => {
      AXIOS.get("Products/GetProductinventory?type=sub")
          .then((res) => {
            if(res.data.Products.length > 0) {
            commit("set_inventory_products", res.data.Products);
            }else {
              commit("set_inventory_products", []);
            }
          })
          .catch((err) => {
            err.message;
          });
    },

    getServiceProductsAction: ({ commit }) => {
      AXIOS.get("Products/GetProductService")
        .then((res) => {
          commit("set_service_products", res.data);
        })
        .catch((err) => {
          err.message;
        });
    },

    getNoninventoryProductsAction: ({ commit }) => {
      AXIOS.get("Products/GetProductNonInventory")
        .then((res) => {
          commit("set_nonInventory_products", res.data);
        })
        .catch((err) => {
          err.message;
        });
    },

    checkNameExistAction: ({ commit }, name) => {
      AXIOS.get(`Products/ProductNameExist/${name}`)
        .then((res) => {
          // (res);
          if (res.status === 200) {
            commit("setNameExistMsg", "");
          }
        })
        .catch((err) => {
          err.message;
          // (err.response.status);
          // if(error?.response?.status === 500)  {
          //     ('409');
          // }
        });
    },

    setNameExistMsgAction: ({ commit }, data) => {
      commit("setNameExistMsg", data);
      // ('from action', data);
    },

    get_ProductList: ({ commit }, payload) => {
      if (payload) {
        AXIOS.get(`api/GS1List?search=${payload}`)
          .then((res) => {
            commit("set_Products", res.data.GS1List);
          })
          .catch((err) => {
            err.message;
          });
      } else {
        AXIOS.get(`api/GS1List`)
          .then((res) => {
            commit("set_Products", res.data.GS1List);
          })
          .catch((err) => {
            err.message;
          });
      }
    },

    getProductsForInvoices: ({ commit, dispatch }) => {
      AXIOS.post(`Forms/ListProduct`, {})
        .then((res) => {
          commit("set_listProducts", res.data.Products);
          dispatch("set_autoSelectedProduct_action");
        })
        .catch((err) => {
          err.message;
        });
    },

    nonInventory_service_Action: ({ commit }) => {
      AXIOS.get("Products/GetProductServiceAndNonInventory")
        .then((res) => {
          commit("set_nonInventory_service", res.data.Products);
        })
        .catch((err) => {
          err.message;
        });
    },

    addClassAction: async ({ dispatch, commit, state }, payload) => {
      return AXIOS.post("Forms/AddClass", {
        class_name: payload.class_name,
      })
        .then((res) => {
          if (res.status === 200) {
            dispatch("getClassesAction");
            setTimeout(() => {
              commit(
                "set_autoSelectedClass",
                state.classes[state.classes.length - 1]
              );
            }, 1000);
          }
          return res;
        })
        .catch((err) => {
          err.message;
        });
    },

    set_autoSelectedProduct_action: ({ commit, state }) => {
      commit(
        "set_autoSelectedProduct",
        state.listProducts[state.listProducts.length - 1]
      );
    },
  },
  modules: {},
};
