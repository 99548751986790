import { AXIOS } from "../../../axios";

export default {
  namespaced: true,
  state: {
    expenses: [],
    vendors: [],
    paymentAccounts: [],
    coa: [], //list of charts of accounts for first table
    invnetory_items: [],
    nonInventory_service: [],
    loading: true,
    expenseById: {},
    viewLoading: true,
    coaAccounts: [],
    expensesAndProducts: [],
    expenseNextItem: "",
    addCoaBtn: false,
    addProductBtn: false,
    autoSelectedProductOrCoa: {},
    showTable: false,
    itemsForBill: [],
    BranchesEnable: 0,
    editExpensesModalDisplay: "none",
  },
  getters: {
    expenses: (state) => state.expenses,
    vendors: (state) => state.vendors,
    paymentAccounts: (state) => state.paymentAccounts,
    coa: (state) => state.coa,
    invnetory_items: (state) => state.invnetory_items,
    nonInventory_service: (state) => state.nonInventory_service,
    loading: (state) => state.loading,
    expenseById: (state) => state.expenseById,
    viewLoading: (state) => state.viewLoading,
    expensesAndProducts: (state) => state.expensesAndProducts,
    expenseNextItem: (state) => state.expenseNextItem,
    addCoaBtn: (state) => state.addCoaBtn,
    addProductBtn: (state) => state.addProductBtn,
    autoSelectedProductOrCoa: (state) => state.autoSelectedProductOrCoa,
    showTable: (state) => state.showTable,
    itemsForBill: (state) => state.itemsForBill,
    BranchesEnable: (state) => state.BranchesEnable,
    editExpensesModalDisplay: (state) => state.editExpensesModalDisplay,
  },
  mutations: {
    setExpenses: (state, data) => {
      state.expenses = data;
    },

    setVendors: (state, data) => {
      state.vendors = data;
    },

    setPaymentAccount: (state, data) => {
      state.paymentAccounts = data;
    },

    setCoa: (state, data) => {
      state.coa = data;
    },

    set_invnetory_items: (state, data) => {
      state.invnetory_items = data;
    },

    set_nonInventory_service: (state, data) => {
      state.nonInventory_service = data;
    },

    set_loading: (state, data) => {
      state.loading = data;
    },

    set_expenseById: (state, data) => {
      state.expenseById = data;
    },

    set_viewLoading: (state, data) => {
      state.viewLoading = data;
    },

    set_expensesAndProducts: (state, data) => {
      state.expensesAndProducts = data;
    },

    set_expenseNextItem: (state, data) => {
      state.expenseNextItem = data;
    },

    set_addProductBtn: (state, data) => {
      state.addProductBtn = data;
    },

    set_addCoaBtn: (state, data) => {
      state.addCoaBtn = data;
    },

    set_autoSelectedProductOrCoa: (state, data) => {
      state.autoSelectedProductOrCoa = data;
    },

    set_showTable: (state, data) => {
      state.showTable = data;
    },
    set_itemsForBill: (state, data) => {
      state.itemsForBill = data;
    },
    set_BranchesEnable: (state, data) => {
      state.BranchesEnable = data;
    },
    set_editExpensesModalDisplay: (state, data) => {
      state.editExpensesModalDisplay = data;
    },
  },
  actions: {
    openAddExpensePopup({ commit }) {
      const addExpenseModal = document.getElementById("addExpenseModal");
      addExpenseModal.style.display = "flex";
    },

    closeAddExpensePopup({ commit }) {
      const addExpenseModal = document.getElementById("addExpenseModal");
      addExpenseModal.style.display = "none";
    },
    openEditExpensePopup({ commit }) {
      const editExpenseModal = document.getElementById("editExpenseModal");
      editExpenseModal.style.display = "flex";
      commit("set_editExpensesModalDisplay", "flex");
    },
    closeEditExpensesPopup({ commit }) {
      const editExpenseModal = document.getElementById("editExpenseModal");
      editExpenseModal.style.display = "none";
      commit("set_editExpensesModalDisplay", "none");
    },

    getExpensesAction: ({ commit }, search) => {
      AXIOS.post("Expenses/GetExpenses", { search: search })
        .then((res) => {
          commit("setExpenses", res.data.MyExpenses);
          commit("set_expenseNextItem", res.data.NextItem);
          commit("set_BranchesEnable", res.data.BranchesEnable);
          commit("set_showTable", res.data.ShowTable);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_loading", false);
        });
    },

    getVendorsAction: ({ commit }) => {
      AXIOS.post("Vendor/ListVendor", {})
        .then((res) => {
          commit("setVendors", res.data.Vendors);
        })
        .catch((err) => {
          err.message;
        });
    },

    getPaymentAccountsAction: ({ commit }) => {
      AXIOS.get("Forms/GetPaymentAccounts")
        .then((res) => {
          commit("setPaymentAccount", res.data.COAList);
        })
        .catch((err) => {
          err.message;
        });
    },

    getCoaAction: ({ commit }) => {
      AXIOS.post("COA/ListCOA", {})
        .then((res) => {
          commit("setCoa", res.data.COA);
        })
        .catch((err) => {
          err.message;
        });
    },

    get_invnetory_items_action: ({ commit }) => {
      AXIOS.get("Products/GetProductinventory")
        .then((res) => {
          commit("set_invnetory_items", res.data.Products);
        })
        .catch((err) => {
          err.message;
        });
    },

    nonInventory_service_Action: ({ commit }) => {
      AXIOS.get("Products/GetProductServiceAndNonInventory")
        .then((res) => {
          commit("set_nonInventory_service", res.data.Products);
        })
        .catch((err) => {
          err.message;
        });
    },

    get_expenseById_action: ({ commit }, id) => {
      AXIOS.get(`Expenses/GetExpensesById/${id}`)
        .then((res) => {
          commit("set_expenseById", res.data);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_viewLoading", false);
        });
    },

    set_expense_preview_object: ({ commit }, payload) => {
      localStorage.setItem("expense_payload", JSON.stringify(payload));
    },

    get_expensesAndProducts_action: ({ commit, dispatch }) => {
      AXIOS.get("Forms/GetItemsForExpenses", {})
        .then((res) => {
          commit("set_expensesAndProducts", res.data);
          dispatch("set_autoSelectedProductOrCoa_action");
        })
        .catch((err) => {
          err.message;
        });
    },

    // get_itemsForBill_action: ({ commit }) => {
    //   AXIOS.get("Forms/GetItemsForBill", {})
    //     .then((res) => {
    //       commit("set_itemsForBill", res.data);
    //     })
    //     .catch((err) => {
    //       (err.message);
    //     });
    // },

    deleteExpenseAction: ({ dispatch }, payload) => {
      AXIOS.get(`Expenses/DeleteExpenses/${payload.id}`)
        .then((res) => {
          // (res);
        })
        .catch((err) => {
          err.message;
        });
    },

    set_addProductBtn_action: ({ commit }, data) => {
      commit("set_addProductBtn", data);
    },

    set_addCoaBtn_action: ({ commit }, data) => {
      commit("set_addCoaBtn", data);
    },
    set_autoSelectedProductOrCoa_action: ({ commit, state }) => {
      commit(
        "set_autoSelectedProductOrCoa",
        state.expensesAndProducts[state.expensesAndProducts.length - 1]
      );
    },

    // set_autoSelectedProductOrCoa_action: ({ commit, state }) => {
    //   commit(
    //     "set_autoSelectedProductOrCoa",
    //     state.expensesAndProducts[state.expensesAndProducts.length - 1]
    //   );
    // },
    // set_autoSelectitemsForBill_action:({commit,state})=>{
    //     commit('set_itemsForBill',state.itemsForBill[state.itemsForBill.length-1])
    // }
  },
  modules: {},
};
