import axios from "axios";

import Vue from "vue";

import { AXIOS } from "../../../axios";

export default {
  namespaced: true,
  state: {
    invoices: [],
    // customers: [],
    terms: [],
    payment_methods: [],
    invoice_id: 0,
    invoice_preview_object: {},
    loading: true,
    invoiceByID: {},
    viewLoading: true,
    amount_received: "",
    refExistMsg: "",
    invoiceNextItem: "",
    receivePaymentNextItem: "",
    InvoicesDoughnutChart: {},
    emptyInvoiceFlag: 0,
    showTable: false,
    recievepayment_id: "",
    receivePaymentById: {},
    BranchesEnable: 0,
    invoicesDonutLoading: true,
    salesLoading: true,
    salesChartLoading: true,
    salesTableLoading: true,
    addInvoiceModalDisplay: "none",
    editInvoiceModalDisplay: "none",
  },
  getters: {
    invoices: (state) => state.invoices,

    customers: (state) => state.customers,

    terms: (state) => state.terms,

    payment_methods: (state) => state.payment_methods,

    invoice_id: (state) => state.invoice_id,

    invoice_preview_object: (state) => state.invoice_preview_object,

    loading: (state) => state.loading,

    invoiceByID: (state) => state.invoiceByID,

    viewLoading: (state) => state.viewLoading,

    amount_received: (state) => state.amount_received,

    refExistMsg: (state) => state.refExistMsg,

    invoiceNextItem: (state) => state.invoiceNextItem,

    receivePaymentNextItem: (state) => state.receivePaymentNextItem,

    InvoicesDoughnutChart: (state) => state.InvoicesDoughnutChart,

    emptyInvoiceFlag: (state) => state.emptyInvoiceFlag,

    showTable: (state) => state.showTable,

    recievepayment_id: (state) => state.recievepayment_id,

    receivePaymentById: (state) => state.receivePaymentById,
    BranchesEnable: (state) => state.BranchesEnable,

    invoicesDonutLoading: (state) => state.invoicesDonutLoading,
    salesLoading: (state) => state.salesLoading,

    salesChartLoading: (state) => state.salesChartLoading,
    salesTableLoading: (state) => state.salesTableLoading,
    addInvoiceModalDisplay: (state) => state.addInvoiceModalDisplay,
    editInvoiceModalDisplay: (state) => state.editInvoiceModalDisplay,
  },
  mutations: {
    setInvoices: (state, data) => {
      state.invoices = data;
    },

    setTerms: (state, data) => {
      state.terms = data;
    },

    setPayment_methods: (state, data) => {
      state.payment_methods = data;
    },

    setInoviceId: (state, data) => {
      state.invoice_id = data;
    },

    set_invoice_preview_object: (state, data) => {
      state.invoice_preview_object = data;
      // ('from mutation', state.invoice_preview_object);
    },

    set_loading: (state, data) => {
      state.loading = data;
    },

    set_invoiceByID: (state, data) => {
      state.invoiceByID = data;
    },

    set_viewLoading: (state, data) => {
      state.viewLoading = data;
    },

    set_amount_received: (state, data) => {
      state.amount_received = data;
    },

    set_refExistMsg: (state, data) => {
      state.refExistMsg = data;
    },

    set_invoiceNextItem: (state, data) => {
      state.invoiceNextItem = data;
    },

    set_receivePaymentNextItem: (state, data) => {
      state.receivePaymentNextItem = data;
    },

    set_InvoicesDoughnutChart: (state, data) => {
      state.InvoicesDoughnutChart = data;
    },

    set_emptyInvoiceFlag: (state, data) => {
      state.emptyInvoiceFlag = data;
    },

    set_showTable: (state, data) => {
      state.showTable = data;
    },

    set_recievepayment_id: (state, data) => {
      state.recievepayment_id = data;
    },

    set_receivePaymentById: (state, data) => {
      state.receivePaymentById = data;
    },
    set_BranchesEnable: (state, data) => {
      state.BranchesEnable = data;
    },
    set_invoicesDonutLoading: (state, data) => {
      state.invoicesDonutLoading = data;
    },
    set_salesLoading: (state, data) => {
      state.salesLoading = data;
    },
    set_salesTableLoading: (state, data) => {
      state.salesTableLoading = data;
    },
    set_addInvoiceModalDisplay: (state, data) => {
      state.addInvoiceModalDisplay = data;
    },
    set_editInvoiceModalDisplay: (state, data) => {
      state.editInvoiceModalDisplay = data;
    },
  },
  actions: {
    openAddInvoicePopup({ commit }) {
      const addInvoiceModal = document.getElementById("addInvoiceModal");
      addInvoiceModal.style.display = "flex";
      commit("set_addInvoiceModalDisplay", "flex");
    },
    openEditInvoicePopup({ commit }) {
      const editInvoiceModal = document.getElementById("editInvoiceModal");
      editInvoiceModal.style.display = "flex";
      commit("set_editInvoiceModalDisplay", "flex");
    },
    closeEditInvoicePopup({ commit }) {
      const editInvoiceModal = document.getElementById("editInvoiceModal");
      editInvoiceModal.style.display = "none";
      commit("set_editInvoiceModalDisplay", "none");
    },
    closeAddInvoicePopup() {
      const addInvoiceModal = document.getElementById("addInvoiceModal");
      addInvoiceModal.style.display = "none";
    },

    openAddInstallmentPopup() {
      const addInstallmentModal = document.getElementById(
        "addInstallmentModal"
      );
      addInstallmentModal.style.display = "block";
    },

    closeAddInstallmentPopup() {
      const addInstallmentModal = document.getElementById(
        "addInstallmentModal"
      );
      addInstallmentModal.style.display = "none";
    },

    openReceivePaymentPopup() {
      const receivePaymentModal = document.getElementById(
        "receivePaymentModal"
      );
      receivePaymentModal.style.display = "flex";
    },

    closeReceivePaymentPopup() {
      const receivePaymentModal = document.getElementById(
        "receivePaymentModal"
      );
      receivePaymentModal.style.display = "none";
    },

    getTermsAction: ({ commit }) => {
      AXIOS.get(`Forms/GetTerms`)
        .then((res) => {
          commit("setTerms", res.data);
        })
        .catch((err) => {
          err.message;
        });
    },

    getPaymentMethodsAction: ({ commit }) => {
      AXIOS.get(`Forms/GetPaymentMethods`)
        .then((res) => {
          commit("setPayment_methods", res.data);
        })
        .catch((err) => {
          err.message;
        });
    },

    set_invoice_preview_object_action: ({ commit }, payload) => {
      commit("set_invoice_preview_object", payload);
      localStorage.setItem("invoice_payload", JSON.stringify(payload));
    },

    get_invoiceByID_action: ({ commit }, id) => {
      commit("set_invoiceByID", {});
      commit("set_receivePaymentNextItem", "");
      AXIOS.get(`Invoice/GetInvoiceById/${id}`)
        .then((res) => {
          commit("set_invoiceByID", res.data.Invoice);
          commit("set_receivePaymentNextItem", res.data.NextItem);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_viewLoading", false);
        });
    },

    update_amount_received_action: ({ commit }, payload) => {
      commit("set_amount_received", payload);
    },

    checkRefExistAction: ({ commit }, ref) => {
      AXIOS.get(`Invoice/InvoiceIDExist/${ref}`)
        .then((res) => {
          // (res);
          if (res.status === 200) {
            commit("set_refExistMsg", "");
          }
        })
        .catch((err) => {
          err.message;
        });
    },

    setRefExistMsgAction: ({ commit }, data) => {
      commit("set_refExistMsg", data);
      // ('from action', data);
    },

    deleteInvoiceAction: ({ dispatch }, payload) => {
      AXIOS.get(`Invoice/DeleteInvoice/${payload.id}`)
        .then((res) => {
          // (res);
        })
        .catch((err) => {
          err.message;
        });
    },

    get_doughnutChartData_action({ commit }) {
      AXIOS.get("Invoice/AllInvoiceStatus").then((res) => {
        commit("set_invoicesDonutLoading", false);
        commit("set_InvoicesDoughnutChart", res.data);
      });
      var flag = 0;
      for (let index = 0; index < data.Series.length; index++) {
        if (data.Series[index] == 0) {
          // this.emptyInvoiceFlag++;
          flag++;
          commit("set_emptyInvoiceFlag", flag);
        }
      }
    },

    closeAddInvoicePopup() {
      const addInvoiceModal = document.getElementById("addInvoiceModal");
      addInvoiceModal.style.display = "none";
    },

    openAddInstallmentPopup() {
      const addInstallmentModal = document.getElementById(
        "addInstallmentModal"
      );
      addInstallmentModal.style.display = "block";
    },

    closeAddInstallmentPopup() {
      const addInstallmentModal = document.getElementById(
        "addInstallmentModal"
      );
      addInstallmentModal.style.display = "none";
    },

    openReceivePaymentPopup() {
      const receivePaymentModal = document.getElementById(
        "receivePaymentModal"
      );
      receivePaymentModal.style.display = "flex";
    },

    closeReceivePaymentPopup() {
      const receivePaymentModal = document.getElementById(
        "receivePaymentModal"
      );
      receivePaymentModal.style.display = "none";
    },

    getTermsAction: ({ commit }) => {
      AXIOS.get(`Forms/GetTerms`)
        .then((res) => {
          commit("setTerms", res.data);
        })
        .catch((err) => {
          err.message;
        });
    },

    getPaymentMethodsAction: ({ commit }) => {
      AXIOS.get(`Forms/GetPaymentMethods`)
        .then((res) => {
          commit("setPayment_methods", res.data);
        })
        .catch((err) => {
          err.message;
        });
    },

    getInvoicesAction: ({ commit }, search) => {
      AXIOS.post(`Invoice/GetInvoice`, { search: search })
        .then((res) => {
          // (res.data);
          commit("set_salesTableLoading", false);
          commit("setInvoices", res.data.Invoices);
          commit("set_invoiceNextItem", res.data.NextItem);
          commit("set_showTable", res.data.ShowTable);
          commit("set_BranchesEnable", res.data.BranchesEnable);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_loading", false);
        });
    },

    set_invoice_preview_object_action: ({ commit }, payload) => {
      commit("set_invoice_preview_object", payload);
      localStorage.setItem("invoice_payload", JSON.stringify(payload));
    },

    get_invoiceByID_action: ({ commit }, id) => {
      commit("set_invoiceByID", {});
      commit("set_receivePaymentNextItem", "");
      AXIOS.get(`Invoice/GetInvoiceById/${id}`)
        .then((res) => {
          commit("set_invoiceByID", res.data.Invoice);
          commit("set_receivePaymentNextItem", res.data.NextItem);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_viewLoading", false);
        });
    },

    update_amount_received_action: ({ commit }, payload) => {
      commit("set_amount_received", payload);
    },

    checkRefExistAction: ({ commit }, ref) => {
      AXIOS.get(`Invoice/InvoiceIDExist/${ref}`)
        .then((res) => {
          // (res);
          if (res.status === 200) {
            commit("set_refExistMsg", "");
          }
        })
        .catch((err) => {
          err.message;
        });
    },

    setRefExistMsgAction: ({ commit }, data) => {
      commit("set_refExistMsg", data);
      // ('from action', data);
    },

    deleteInvoiceAction: ({ dispatch }, payload) => {
      AXIOS.get(`Invoice/DeleteInvoice/${payload.id}`)
        .then((res) => {
          // (res);
        })
        .catch((err) => {
          err.message;
        });
    },

    set_recievepayment_id_action: ({ commit }, data) => {
      commit("set_recievepayment_id", data);
    },

    getReceivePaymentById: ({ commit }, id) => {
      AXIOS.get(`Invoice/GetNewReceivePaymentBy/${id}`)
        .then((res) => {
          commit("set_receivePaymentById", res.data.Invoice);
        })
        .catch((err) => {
          err.message;
        });
    },
    set_recievepayment_id_action: ({ commit }, data) => {
      commit("set_recievepayment_id", data);
    },

    getReceivePaymentById: ({ commit }, id) => {
      AXIOS.get(`Invoice/GetNewReceivePaymentBy/${id}`)
        .then((res) => {
          commit("set_receivePaymentById", res.data.Invoice);
        })
        .catch((err) => {
          err.message;
        });
    },
  },
  modules: {},
};
