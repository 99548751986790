import Vue from "vue";
import VueRouter from "vue-router";
import usersStore from "../modules/users/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(
        /* webpackChunkName: "landing", webpackPrefetch: true */ "../modules/landing/views/Landing.vue"
      ),
    beforeEnter: (to, from, next) => {
      if (!usersStore.state.token) {
        localStorage.setItem("layout", "landing-layout");
        next();
        return;
      }
      localStorage.setItem("layout", "dashboard-layout");
      next("/dashboard");
    },
  },

  {
    path: "/previewInvoice",
    name: "PreviewInvoice",
    component: () =>
      import(
        /* webpackChunkName: "previewInvoice", webpackPrefetch: true */ "../modules/sales/views/PreviewInvoice.vue"
      ),
    beforeEnter: (to, from, next) => {
      if (usersStore.state.token) {
        localStorage.setItem("layout", "landing-layout");
        next();
        return;
      }
      localStorage.setItem("layout", "landing-layout");
      next("/");
    },
  },

  {
    path: "/printInvoice",
    name: "PrintInvoice",
    component: () =>
      import(
        /* webpackChunkName: "printInvoice", webpackPrefetch: true */ "../modules/sales/views/PrintInvoice.vue"
      ),
    beforeEnter: (to, from, next) => {
      if (usersStore.state.token) {
        localStorage.setItem("layout", "landing-layout");
        next();
        return;
      }
      localStorage.setItem("layout", "landing-layout");
      next("/");
    },
  },

  {
    path: "/previewQuote",
    name: "PreviewQuote",
    component: () =>
      import(
        /* webpackChunkName: "previewQuote", webpackPrefetch: true */ "../modules/quotes/views/PreviewQuote.vue"
      ),
    beforeEnter: (to, from, next) => {
      if (usersStore.state.token) {
        localStorage.setItem("layout", "landing-layout");
        next();
        return;
      }
      localStorage.setItem("layout", "landing-layout");
      next("/");
    },
  },

  {
    path: "/printQuote",
    name: "PrintQuote",
    component: () =>
      import(
        /* webpackChunkName: "printQuote", webpackPrefetch: true */ "../modules/quotes/views/PrintQuote.vue"
      ),
    beforeEnter: (to, from, next) => {
      if (usersStore.state.token) {
        localStorage.setItem("layout", "landing-layout");
        next();
        return;
      }
      localStorage.setItem("layout", "landing-layout");
      next("/");
    },
  },

  {
    path: "/previewExpense",
    name: "PreviewExpense",
    component: () =>
      import(
        /* webpackChunkName: "previewExpense", webpackPrefetch: true */ "../modules/expenses/views/PreviewExpense.vue"
      ),
    beforeEnter: (to, from, next) => {
      if (usersStore.state.token) {
        localStorage.setItem("layout", "landing-layout");
        next();
        return;
      }
      localStorage.setItem("layout", "landing-layout");
      next("/");
    },
  },

  {
    path: "/printExpense",
    name: "PrintExpense",
    component: () =>
      import(
        /* webpackChunkName: "printExpense", webpackPrefetch: true */ "../modules/expenses/views/PrintExpense.vue"
      ),
    beforeEnter: (to, from, next) => {
      if (usersStore.state.token) {
        localStorage.setItem("layout", "landing-layout");
        next();
        return;
      }
      localStorage.setItem("layout", "landing-layout");
      next("/");
    },
  },

  {
    path: "/previewBill",
    name: "PreviewBill",
    component: () =>
      import(
        /* webpackChunkName: "previewBill", webpackPrefetch: true */ "../modules/bill/views/PreviewBill.vue"
      ),
    beforeEnter: (to, from, next) => {
      if (usersStore.state.token) {
        localStorage.setItem("layout", "landing-layout");
        next();
        return;
      }
      localStorage.setItem("layout", "landing-layout");
      next("/");
    },
  },

  {
    path: "/printBill",
    name: "PrintBill",
    component: () =>
      import(
        /* webpackChunkName: "printBill", webpackPrefetch: true */ "../modules/bill/views/PrintBill.vue"
      ),
    beforeEnter: (to, from, next) => {
      if (usersStore.state.token) {
        localStorage.setItem("layout", "landing-layout");
        next();
        return;
      }
      localStorage.setItem("layout", "landing-layout");
      next("/");
    },
  },

  {
    path: "/notAuthorizedForProject",
    name: "NotAuthorizedForProject",
    component: () =>
      import(
        /* webpackChunkName: "notAuthorizedForProject", webpackPrefetch: true */ "../views/NotAuthorizedForProject.vue"
      ),
    beforeEnter: (to, from, next) => {
      if (usersStore.state.token) {
        localStorage.setItem("layout", "landing-layout");
        next();
        return;
      }
      localStorage.setItem("layout", "landing-layout");
      next("/");
    },
  },
  {
    path: "*",
    name: "notfound",
    component: () =>
      import(
        /* webpackChunkName: "notfound", webpackPrefetch: true */ "../views/Page404.vue"
      ),
    beforeEnter: (to, from, next) => {
      if (usersStore.state.token) {
        // localStorage.setItem('layout', 'landing-layout');
        next();
        return;
      }
      // localStorage.setItem('layout', 'landing-layout');
      next();
    },
  },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes,
});

export default router;
