import store from "./store";

const moduleRoute = {
  path: "/",
  component: () =>
    import(
      /* webpackChunkName: "landing-module", webpackPrefetch: true */ "./Module.vue"
    ),
  children: [
    {
      path: "/",
      component: () =>
        import(
          /* webpackChunkName: "coming-soon", webpackPrefetch: true */ "./views/ComingSoon.vue"
        ),
    },
    {
      path: "/resources",
      component: () =>
        import(
          /* webpackChunkName: "resources", webpackPrefetch: true */ "./views/Resources.vue"
        ),
    },
    {
      path: "/privacy",
      component: () =>
        import(
          /* webpackChunkName: "privacy-policy", webpackPrefetch: true */ "./views/PrivacyPolicy.vue"
        ),
    },
    {
      path: "/terms&conditions",
      component: () =>
        import(
          /* webpackChunkName: "terms-and-conditions", webpackPrefetch: true */ "./views/TermsAndConditions.vue"
        ),
    },
    {
      path: "/subscriptions",
      component: () =>
        import(
          /* webpackChunkName: "subscriptions", webpackPrefetch: true */ "./views/Subscriptions"
        ),
    },

    {
      path: "/subscribtions",
      component: () =>
        import(
          /* webpackChunkName: "subscriptions", webpackPrefetch: true */ "./views/Subscribtions"
        ),
    },
  ],
  beforeEnter: (to, from, next) => {
    if (!store.state.token) {
      localStorage.setItem("layout", "landing-layout");
      next();
      return;
    }
    localStorage.setItem("layout", "dashboard-layout");
    next("/dashboard");
  },
};

export default (router) => {
  router.addRoute(moduleRoute);
};
