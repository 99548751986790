import { AXIOS } from "../../../axios";
import { successToast, errorToast } from "@/helpers/toastifications";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    companyData: {},
    Branches: [],
    companyLoading: true,
    card_list: [],
    loading: true,
    cards_loading: true,
    recieveBranchById: null,
    disabled: false,
    enableBranch: {},
    autoSelectedBranch: {},
    BranchesEnable: 0,
    EInvoice: false,
  },

  getters: {
    companyData: (state) => state.companyData,
    companyLoading: (state) => state.companyLoading,
    card_list: (state) => state.card_list,
    loading: (state) => state.loading,
    cards_loading: (state) => state.cards_loading,
    Branches: (state) => state.Branches,
    recieveBranchById: (state) => state.recieveBranchById,
    disabled: (state) => state.disabled,
    autoSelectedBranch: (state) => state.autoSelectedBranch,
    BranchesEnable: (state) => state.BranchesEnable,
    getEInvoiceStatus: (state) => state.EInvoice,
  },
  mutations: {
    set_companyData: (state, data) => {
      state.companyData = data;
    },

    set_companyLoading: (state, data) => {
      state.companyLoading = data;
    },

    set_card_list: (state, data) => {
      state.card_list = data;
    },

    set_cards_loading: (state, data) => {
      state.cards_loading = data;
    },
    set_branches: (state, data) => {
      state.Branches = data;
    },
    branch_enable: (state, data) => {
      state.BranchesEnable = data;
    },
    set_loading: (state, data) => {
      state.loading = data;
    },
    set_recieveBranchById: (state, data) => {
      state.recieveBranchById = data;
    },
    set_disabled: (state, data) => {
      state.disabled = data;
    },
    set_enable_branch: (state, data) => {
      state.enableBranch = data;
    },
    set_auto_selected_bracnhes: (state, data) => {
      state.autoSelectedBranch = data;
    },
    set_E_Invoice_Status: (state, data) => {
      state.EInvoice = data;
    },
  },
  actions: {
    openAddCardPopup() {
      const addCardModal = document.getElementById("addCardModal");
      addCardModal.style.display = "flex";
    },

    closeAddCardPopup() {
      const addCardModal = document.getElementById("addCardModal");
      addCardModal.style.display = "none";
    },
    openEditSubscripePopup() {
      const editSubscribeModal = document.getElementById("editSubscribeModal");
      editSubscribeModal.style.display = "flex";
    },
    closeEditSubscripePopup() {
      const editSubscribeModal = document.getElementById("editSubscribeModal");
      editSubscribeModal.style.display = "none";
    },
    openAddBranchPopUp() {
      const addBranchesModal = document.getElementById("addBranchesModall");
      addBranchesModal;
      addBranchesModal.style.display = "flex";
    },
    closeAddBranchPopup() {
      const addBranchesModal = document.getElementById("addBranchesModall");
      addBranchesModal;
      addBranchesModal.style.display = "none";
    },

    openEditBranchesPopup() {
      const editCardModal = document.getElementById("editBranchModal");
      editCardModal.style.display = "flex";
    },
    closeEditBranchPopup() {
      const editCardModal = document.getElementById("editBranchModal");
      editCardModal.style.display = "none";
    },
    openViewBranchesPopup(stte) {
      const editCardModal = document.getElementById("viewBranchesModal");
      editCardModal.style.display = "flex";
    },
    closeViewBranchesPopup() {
      const editCardModal = document.getElementById("viewBranchesModal");
      editCardModal.style.display = "none";
    },
    openEnableBranchPopup() {
      const enableBranch = document.getElementById("enable-popup");
      enableBranch.style.display = "flex";
    },
    closeEnableBranch() {
      const enableBranch = document.getElementById("enable-popup");
      enableBranch.style.display = "none";
    },
    disabledButton() {
      const disabled = document.querySelector(".enabled-btn");
      disabled.setAttribute("disabled", "");
      disabled.style.background = "#519D6F";
      disabled.style.border = "2px solid #519D6F";
      disabled.style.color = "white";
      disabled.innerText = this.$t("Setting.enable");
    },
    addCardAction: async ({ commit, dispatch }, payload) => {
      try {
        return AXIOS.post("Setting/Payment/SaveCardToken", {
          nameOnCard: payload.full_name,
          cardNumber: payload.card_number,
          securityCode: payload.cvv,
          expiryMonth: payload.month,
          expiryYear: payload.year,
        }).then((res) => {
          if (res.status === 200) {
            dispatch("closeAddCardPopup");
            // Vue.$toast.success('Card Added Successfully!', {
            //     timeout: 5000
            // })

            dispatch("get_card_list_action");
          }
          return res;
        });
      } catch (error) {
        return error;
      }
    },

    get_companyData_action: ({ commit, rootState }) => {
      return new Promise((resolve, reject) => {
        AXIOS.post("Setting/GetCompany")
          .then((res) => {
            commit("set_companyData", res.data.Company);
            commit("set_companyLoading", false);

            localStorage.setItem("companyName", res.data.Company.CompanyName);
            localStorage.setItem(
              "company_address",
              res.data.Company.CompanyAddress
            );
            if (res.data.Company.company_image) {
              console.log("dddddasdsdasasdasdasdasdasdqwdqwdwqdqw");
              rootState.companyLogo = `${
                res.data.Company.company_image.url
              }?XTenant=${localStorage.getItem("xtenant")}`;
              localStorage.setItem(
                "companyLogo",
                `${
                  res.data.Company.company_image.url
                }?XTenant=${localStorage.getItem("xtenant")}`
              );
              console.log(localStorage.getItem("companyLogo"));
            }
            rootState.companyName = res.data.Company.CompanyName;
            resolve();
          })
          .catch((err) => {
            err.message;
          })
          .finally(() => {
            commit("set_companyLoading", false);
          });
      });
    },

    update_companyData_action: ({ dispatch }, payload) => {
      let data = new FormData();
      payload.companyName !== undefined
        ? data.append("company_name", payload.companyName)
        : null;
      payload.legalName !== undefined
        ? data.append("legal_name", payload.legalName)
        : null;
      payload.companyEmail !== undefined
        ? data.append("company_main_email", payload.companyEmail)
        : null;
      payload.billingEmail !== undefined
        ? data.append("billing_email", payload.billingEmail)
        : null;
      payload.companyAddress !== undefined
        ? data.append("company_address", payload.companyAddress)
        : null;
      payload.phone !== undefined ? data.append("phone", payload.phone) : null;
      payload.uploadedFile !== undefined
        ? data.append("files[0]", payload.uploadedFile)
        : null;

      return AXIOS.post("Setting/UpdateCompanyData", data)
        .then((res) => {
          console.log(res, "resssss");
          if (res.status == 200) {
            dispatch("get_companyData_action");
            successToast("Company Data Updated Successfully!");
            if (res.data.file?.url) {
              rootState.companyLogo = `${
                res.data.file.url
              }?XTenant=${localStorage.getItem("xtenant")}`;
              localStorage.setItem(
                "companyLogo",
                `${res.data.file.url}?XTenant=${localStorage.getItem(
                  "xtenant"
                )}`

              );

         
            }
            window.location.reload();
          }
          return res;
        }).catch((err) => {
          err.message;
        });
    },

    cancelPlan_action: ({ commit }) => {
      AXIOS.post("Setting/Subscription/CancelSubscription", {})
        .then((res) => {
          if (res.status === 200) {
            successToast("Plan cancelled successfully!");
          }
        })
        .catch((err) => {
          err.message;
        });
    },

    get_card_list_action: ({ commit }) => {
      AXIOS.post("Setting/Payment/GetAllCards", {})
        .then((res) => {
          commit("set_card_list", res.data.COAList);
          commit("set_cards_loading", false);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_cards_loading", false);
        });
    },

    get_branches_data: ({ commit, dispatch, state }) => {
      AXIOS.get("Branch/GetBranches", {})
        .then((res) => {
          commit("set_branches", res.data.Branches);
          commit("branch_enable", res.data.BranchesEnable);
          if (res.data.BranchesEnable == 1) {
            dispatch("disabledButton");
          }
          commit("set_loading", false);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_loading", false);
        });
    },
    getBranchesById: ({ commit }, id) => {
      AXIOS.get(`Branch/GetBranchByID/${id}`)
        .then((res) => {
          commit("set_recieveBranchById", res.data.data);
          // ( "respose" ,res.data.data)
        })
        .catch((err) => {
          err.message;
        });
    },

    addBranch: async ({ commit, dispatch }, payload) => {
      return AXIOS.post(`Branch/AddBranch`, {
        street_name: payload.street_name,
        phone_number: payload.phone_number,
        name: payload.name,
        postal_code: payload.postal_code,
        email: payload.email,
        street_no: payload.street_no,
      })
        .then((res) => {
          if (res.status === 200) {
            // console.log(res)
            // const trueMessage = true;
            // resolve(trueMessage);
            dispatch("closeAddBranchPopup");
            successToast("Branch Added Successfully!");
            dispatch("get_branches_data");
            setTimeout(() => {
              dispatch("set_autoSelectedBranch_action");
            }, 1000);
            //  commit("set_auto_selected_accounts", (state.chartsofAccountsTable - 1));
          } else {
            reject("Something went wrong");
          }
          return res;
        })
        .catch((error) => {
          reject(error);
        });
    },

    editBranch: ({ dispatch }, payload) => {
      AXIOS.put(`Branch/EditBranch/${payload.id}`, {
        name: payload.name,
        street_name: payload.street_name,
        phone_number: payload.phone_number,
        postal_code: payload.postal_code,
        email: payload.email,
        street_no: payload.street_no,
      })
        .then((res) => {
          console.log(2)
          if (res.status == 200) {
            console.log(1)
            dispatch("closeEditBranchPopup");
            dispatch("closeViewBranchesPopup");
            successToast("Edited Successfully");
            dispatch("get_branches_data");
          }
        })
        .catch((err) => {
          err.message;
        });
    },

    get_ProductList: ({ commit }) => {
      AXIOS.get("api/GS1List")
        .then((res) => {
          commit("set_Products", res.data.GS1List);
        })
        .catch((err) => {
          err.message;
        });
    },
    getEInvoiceStatus: ({ commit }) => {
      AXIOS.get("Einvoice/checkifActive")
        .then((res) => {
          commit("set_E_Invoice_Status", res.data);
          console.log(res);
          res.data
            ? localStorage.setItem("isActive", "1")
            : localStorage.setItem("isActive", "0");
          resolve(res.data);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_loading", false);
        });
    },
    updateEInvoiceStatus: ({ commit }) => {
      return AXIOS.get("Einvoice/UpdateIsActiveStatus")
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          err.message;
        });
    },
    deleteBranch: ({ dispatch }, id) => {
      AXIOS.delete(`/Branch/DeleteBranch/${id}`).then((res) => {
        if (res.status == 200) {
          successToast("Information deleted successfully!");
          dispatch("closeViewBranchesPopup");
          dispatch("get_branches_data");
        }
      });
    },

    enableBranch: ({ commit, dispatch }, payload) => {
      AXIOS.post("Branch/EnableBranches", {
        enable: "yes",
      }).then((res) => {
        if (res.status == 200) {
          successToast("Branches Activated successfully!");
          dispatch("closeEnableBranch");
          dispatch("disabledButton");
          location.reload();
        }
      });
    },
    // getBranch: ({ commit, dispatch }, payload) => {
    //     AXIOS.get('Setting/GetCompany').then(res => {
    //         if (res.status == 200) {
    //             if (res.data.Company.BranchesEnable == 1) {
    //                 dispatch('disabledButton');
    //             }
    //         }
    //     })
    // },

    set_autoSelectedBranch_action: ({ commit, state, dispatch }) => {
      dispatch("get_branches_data");
      commit(
        "set_auto_selected_bracnhes",
        state.Branches[state.Branches.length - 1]
      );
    },
  },
  modules: {},
};
