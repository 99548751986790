import moment from "moment";

export function getAmount(quantity, rate) {
  return Number(quantity) * Number(rate);
}

export function getTaxAmount(amount, tax_rate) {
  return Number(amount) * (Number(tax_rate) / 100);
}

export function getTotalAmount(amount, tax_amount) {
  return Number(amount) + Number(tax_amount);
}

export function getRate(amount, quantity) {
  return Number(amount) / Number(quantity);
}

export function showTwoDecimals(x) {
  x = Number(x);
  return x.toFixed(2);
}

export function calcDueAmount(subtotal, subtotaltax, downpayment = 0) {
  return Number(
    (Number(subtotal) + Number(subtotaltax) - Number(downpayment)).toFixed(2)
  );
}

export function numberWithCommas(x, withComma = true) {
  if (withComma) {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return x?.toString();
}

export function isBefore(date1, date2) {
  return date1 < date2;
}

export function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return moment([year, month, day].join("-"), "YYYY-MM-DD")._i;
}

export function generatePassword(digits) {
  return Math.floor(Math.random() * (10 * digits));
}
