import axios from "axios";
import Vue from "vue";
import { successToast } from "@/helpers/toastifications";
import router from "../../../router";
import { AXIOS } from "../../../axios";

export default {
  namespaced: true,
  state: {
    quotes: [],
    customers: [],
    terms: [],
    loading: true,
    QuoteByID: {},
    invoiceID: "",
    viewLoading: true,
    quote_preview_object: {},
    refExistMsg: "",
    showTable: false,
    productsTableForEdit: [],
    quoteNextItem: "",
    quotesDonutLoading: true,
  },
  getters: {
    quotes: (state) => state.quotes,

    customers: (state) => state.customers,

    terms: (state) => state.terms,

    loading: (state) => state.loading,

    QuoteByID: (state) => state.QuoteByID,

    invoiceID: (state) => state.invoiceID,

    viewLoading: (state) => state.viewLoading,

    quote_preview_object: (state) => state.quote_preview_object,

    refExistMsg: (state) => state.refExistMsg,

    productsTableForEdit: (state) => state.productsTableForEdit,

    quoteNextItem: (state) => state.quoteNextItem,

    showTable: (state) => state.showTable,

    quotesDonutLoading: (state) => state.quotesDonutLoading,
  },
  mutations: {
    setQuotes: (state, data) => {
      state.quotes = data;
    },

    setCustomers: (state, data) => {
      state.customers = data;
    },

    setTerms: (state, data) => {
      state.terms = data;
    },

    set_loading: (state, data) => {
      state.loading = data;
    },

    set_QuoteByID: (state, data) => {
      state.QuoteByID = data;
    },

    set_invoiceID: (state, data) => {
      state.invoiceID = data;
    },

    set_viewLoading: (state, data) => {
      state.viewLoading = data;
    },

    set_quote_preview_object: (state, data) => {
      state.quote_preview_object = data;
    },

    set_refExistMsg: (state, data) => {
      state.refExistMsg = data;
    },

    set_productsTableForEdit: (state, data) => {
      state.productsTableForEdit = data;
    },

    set_quoteNextItem: (state, data) => {
      state.quoteNextItem = data;
    },
    set_showTable: (state, data) => {
      state.showTable = data;
    },
    set_quotesDonutLoading: (state, data) => {
      state.quotesDonutLoading = data;
    },
  },
  actions: {
    openAddQuotePopup() {
      const addQuoteModal = document.getElementById("addQuoteModal");
      addQuoteModal.style.display = "flex";
    },

    closeAddQuotePopup() {
      const addQuoteModal = document.getElementById("addQuoteModal");
      addQuoteModal.style.display = "none";
    },

    getCustomersAction: ({ commit }) => {
      AXIOS.get(`Forms/GetCustomerForInvoice`)
        .then((res) => {
          // (res.data);
          commit("setCustomers", res.data.Customers);
        })
        .catch((err) => {
          err.message;
        });
    },

    getTermsAction: ({ commit }) => {
      AXIOS.get(`Forms/GetTerms`)
        .then((res) => {
          commit("setTerms", res.data);
        })
        .catch((err) => {
          err.message;
        });
    },

    getQuotesAction: ({ commit }, search) => {
      AXIOS.post(`Quote/GetQuotes`, { search: search })
        .then((res) => {
          commit("setQuotes", res.data.Customers);
          commit("set_quoteNextItem", res.data.NextITem);
          commit("set_showTable", res.data.ShowTable);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_loading", false);
        });
    },

    get_QuoteByID_action: ({ commit }, id) => {
      AXIOS.get(`Quote/GetQuoteeById/${id}`)
        .then((res) => {
          commit("set_QuoteByID", res.data.Quote);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_viewLoading", false);
        });
    },

    convert_QuoteToInvoice_action: ({ commit }, id) => {
      AXIOS.get(`Quote/ConvertQuoteToInvoice/${id}`)
        .then((res) => {
          commit("set_invoiceID", res.data.Invoice_id);
          router.push({ path: `/sales/${res.data.Invoice_id}` });
        })
        .catch((err) => {
          err.message;
        });
    },

    change_quote_action: ({ commit }, payload) => {
      return AXIOS.post(`Quote/ChangeQuoteStatus/${payload.id}`, {
        status: payload.status,
      })
        .then((res) => {
          if (res.status == 200) {
            // Vue.$toast.success("Status Changed successfully", {
            //     timeout: 5000
            // });
            // successToast("Status Changed successfully");
            this.get_QuoteByID_action(payload.id);
          }
          return res;
        })
        .catch((err) => {
          err.message;
        });
    },

    set_quote_preview_object_action: ({ commit }, payload) => {
      localStorage.setItem("quote_preview", JSON.stringify(payload));
    },

    checkRefExistAction: ({ commit }, ref) => {
      AXIOS.get(`Quote/QuoteIDExist/${ref}`)
        .then((res) => {
          // (res);
          if (res.status === 200) {
            commit("set_refExistMsg", "");
          }
        })
        .catch((err) => {
          err.message;
        });
    },

    setRefExistMsgAction: ({ commit }, data) => {
      commit("set_refExistMsg", data);
      // ('from action', data);
    },

    set_productsTableForEdit_action: ({ commit }, data) => {
      // ('set_productsTableForEdit_action', data);
      commit("set_productsTableForEdit", data);
    },

    deleteQuoteAction: ({ dispatch }, payload) => {
      AXIOS.get(`Quote/DeleteQuote/${payload.id}`)
        .then((res) => {
          // (res);
        })
        .catch((err) => {
          err.message;
        });
    },
  },
  modules: {},
};
