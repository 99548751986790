<template>
  <div id="warningToast">
    <div style="position: relative">
      <a
        @click="hide"
        class="en-position"
        :class="{ 'ar-position': isArabicLocale }"
      >
        <img
          width="20"
          height="20"
          src="../../assets/toasts/close.webp"
          alt=""
        />
      </a>
      <div
        class="d-flex align-items-center"
        :class="{ 'text-right': isArabicLocale }"
      >
        <img
          width="20"
          height="20"
          src="../../assets/toasts/warning.webp"
          alt=""
          class="mr-2"
        />

        <div>
          <h5>{{ $t("toast.Heads up") }}</h5>
          <p class="m-0" id="warningText">{{ $t("toast.Warning") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import arabicLocale from "@/mixins/arabicLocale";

export default {
  name: "WarningToast",
  mixins: [arabicLocale],
  methods: {
    hide() {
      var toast = document.getElementById("warningToast");
      toast.classList.remove("show");
    },
  },
};
</script>

<style scoped>
.en-position {
  position: absolute;
  right: 1px;
  top: 1px;
  cursor: pointer;
}
.ar-position {
  position: absolute;
  left: 1px;
  top: 1px;
  cursor: pointer;
}
#warningToast {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background: #fef7ea;
  border: 1px solid #f5a800;
  color: #333c48;
  border-radius: 10px;
  padding: 16px;
  position: fixed;
  z-index: 100;
  left: 50%;
  top: 30px;
  font-size: 16px;
}

#warningToast.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    top: 30px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    top: 30px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}
</style>
