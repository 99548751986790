<template>
  <div id="editSubscribeModal" class="edit-subscribe-Modal">
    <!-- <success-toast
      message="Subscribe.Plan Changed Successfully Start From The Next Month"
      :status="toast_success"
    /> -->

    <div
      style="max-width: 1304px !important"
      class="card mx-5 add-card-content container"
    >
      <div class="card-body" :class="{ 'ar-popup': isArabicLocale }">
        <h1 class="label-price py-3">{{ $t("Subscribe.Plans") }}</h1>
        <p class="select-plan py-2">
          {{ $t("Subscribe.Select the Plan You want to Change") }}
        </p>
        <!-- check the annunal plan  -->
        <div class="flex content-center py-1">
          <div class="px-3">
            <input type="radio" name="subscription" data-col="1" checked />
            <label class="px-2 mb-0" for="">{{
              $t("Subscribe.monthly")
            }}</label>
          </div>
          <div>
            <input type="radio" name="subscription" data-col="2" />
            <label class="px-2 mb-0" for="">{{
              $t("Subscribe.annually")
            }}</label>
          </div>
        </div>
        <!-- plans -->
        <div class="plans">
          <!-- Currenct Plan (Startup Package) -->
          <b-row class="content-around py-4">
            <b-col
              @input="checkplann(plan.plan_id)"
              :class="{ 'startup-package': checkplan == plan.plan_id }"
              v-for="(plan, i) in plans"
              :key="i"
              class="pointer custom-plan px-3 py-3 h-100"
              lg="3"
              md="6"
              sm="12"
            >
              <div class="flex content-center">
                <div class="flex content-center input-check pt-25px">
                  <img
                    class="absolute"
                    v-if="checkplan == plan.plan_id"
                    src="../../../../assets/Group 43456.svg"
                    alt=""
                  />
                  <input
                    :id="plan.plan_id"
                    class="radio-custom"
                    name="radio-group"
                    @click="checkplann(plan.plan_id)"
                    type="radio"
                    checked
                  />
                  <label :for="plan.plan_id" class="radio-custom-label"></label>
                </div>

                <h1 v-if="plan.plan_id == 2" class="startup-package-label">
                  {{ $t("Subscribe.Small to Medium sized companies") }}
                </h1>
                <h1 v-if="plan.plan_id == 1" class="startup-package-label">
                  {{ $t("Subscribe.Startup Package") }}
                </h1>
                <!-- <span
                  v-if="checkplan == plan.plan_id"
                  class="current-plan-span px-2"
                  >{{ $t("Subscribe.Current Plan") }}</span
                > -->
              </div>

              <div>
                <div class="flex content-center">
                  <h1 v-if="plan.plan_id == 2" class="price-number">
                    {{ plan.price_month }}
                  </h1>
                  <h1 v-if="plan.plan_id == 1" class="price-number">250</h1>
                  <span class="month-number flex items-center">{{
                    $t("Subscribe./mo")
                  }}</span>
                </div>
                <p class="text-center egp-center mb-0">
                  {{ $t("Subscribe.EGP Per Month") }}
                </p>
                <p class="mb-0 text-center text-plan py-2 post">
                  {{ $t("Subscribe.For Up to 1 Users") }}
                </p>
                <p class="mb-0 text-center text-plan py-2">
                  {{ $t("Subscribe.Ideal For Freelancers and Startups") }}
                </p>
              </div>
              <hr />
              <div>
                <!-- plan 1 and plan 2  -->
                <div class="flex items-center py-2">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3920_13490)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.41699 10.0003C2.41699 5.81217 5.81217 2.41699 10.0003 2.41699H12.5003C12.9145 2.41699 13.2503 2.08121 13.2503 1.66699C13.2503 1.25278 12.9145 0.916992 12.5003 0.916992H10.0003C4.98374 0.916992 0.916992 4.98374 0.916992 10.0003C0.916992 15.0169 4.98374 19.0837 10.0003 19.0837C15.0169 19.0837 19.0837 15.0169 19.0837 10.0003V9.58366C19.0837 9.16945 18.7479 8.83366 18.3337 8.83366C17.9194 8.83366 17.5837 9.16945 17.5837 9.58366V10.0003C17.5837 14.1885 14.1885 17.5837 10.0003 17.5837C5.81217 17.5837 2.41699 14.1885 2.41699 10.0003ZM18.0307 5.53066C18.3235 5.23776 18.3235 4.76289 18.0307 4.47C17.7378 4.1771 17.2629 4.1771 16.97 4.47L9.96615 11.4738L7.16087 9.01923C6.84914 8.74646 6.37532 8.77805 6.10256 9.08978C5.8298 9.40151 5.86139 9.87533 6.17311 10.1481L9.50645 13.0648C9.8036 13.3248 10.2515 13.3099 10.5307 13.0307L18.0307 5.53066Z"
                        fill="#00205C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3920_13490">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <p class="mb-0 px-2">
                    {{ $t("Subscribe.Track Sales & Expenses") }}
                  </p>
                </div>
                <div class="flex items-center py-2">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3920_13490)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.41699 10.0003C2.41699 5.81217 5.81217 2.41699 10.0003 2.41699H12.5003C12.9145 2.41699 13.2503 2.08121 13.2503 1.66699C13.2503 1.25278 12.9145 0.916992 12.5003 0.916992H10.0003C4.98374 0.916992 0.916992 4.98374 0.916992 10.0003C0.916992 15.0169 4.98374 19.0837 10.0003 19.0837C15.0169 19.0837 19.0837 15.0169 19.0837 10.0003V9.58366C19.0837 9.16945 18.7479 8.83366 18.3337 8.83366C17.9194 8.83366 17.5837 9.16945 17.5837 9.58366V10.0003C17.5837 14.1885 14.1885 17.5837 10.0003 17.5837C5.81217 17.5837 2.41699 14.1885 2.41699 10.0003ZM18.0307 5.53066C18.3235 5.23776 18.3235 4.76289 18.0307 4.47C17.7378 4.1771 17.2629 4.1771 16.97 4.47L9.96615 11.4738L7.16087 9.01923C6.84914 8.74646 6.37532 8.77805 6.10256 9.08978C5.8298 9.40151 5.86139 9.87533 6.17311 10.1481L9.50645 13.0648C9.8036 13.3248 10.2515 13.3099 10.5307 13.0307L18.0307 5.53066Z"
                        fill="#00205C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3920_13490">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <p class="mb-0 px-2">
                    {{ $t("Subscribe.Create and Manage Purchase Orders") }}
                  </p>
                </div>

                <div class="flex items-center py-2">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3920_13490)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.41699 10.0003C2.41699 5.81217 5.81217 2.41699 10.0003 2.41699H12.5003C12.9145 2.41699 13.2503 2.08121 13.2503 1.66699C13.2503 1.25278 12.9145 0.916992 12.5003 0.916992H10.0003C4.98374 0.916992 0.916992 4.98374 0.916992 10.0003C0.916992 15.0169 4.98374 19.0837 10.0003 19.0837C15.0169 19.0837 19.0837 15.0169 19.0837 10.0003V9.58366C19.0837 9.16945 18.7479 8.83366 18.3337 8.83366C17.9194 8.83366 17.5837 9.16945 17.5837 9.58366V10.0003C17.5837 14.1885 14.1885 17.5837 10.0003 17.5837C5.81217 17.5837 2.41699 14.1885 2.41699 10.0003ZM18.0307 5.53066C18.3235 5.23776 18.3235 4.76289 18.0307 4.47C17.7378 4.1771 17.2629 4.1771 16.97 4.47L9.96615 11.4738L7.16087 9.01923C6.84914 8.74646 6.37532 8.77805 6.10256 9.08978C5.8298 9.40151 5.86139 9.87533 6.17311 10.1481L9.50645 13.0648C9.8036 13.3248 10.2515 13.3099 10.5307 13.0307L18.0307 5.53066Z"
                        fill="#00205C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3920_13490">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <p class="mb-0 px-2">
                    {{ $t("Subscribe.Detailed Reports for Management") }}
                  </p>
                </div>

                <div class="flex items-center py-2">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3920_13490)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.41699 10.0003C2.41699 5.81217 5.81217 2.41699 10.0003 2.41699H12.5003C12.9145 2.41699 13.2503 2.08121 13.2503 1.66699C13.2503 1.25278 12.9145 0.916992 12.5003 0.916992H10.0003C4.98374 0.916992 0.916992 4.98374 0.916992 10.0003C0.916992 15.0169 4.98374 19.0837 10.0003 19.0837C15.0169 19.0837 19.0837 15.0169 19.0837 10.0003V9.58366C19.0837 9.16945 18.7479 8.83366 18.3337 8.83366C17.9194 8.83366 17.5837 9.16945 17.5837 9.58366V10.0003C17.5837 14.1885 14.1885 17.5837 10.0003 17.5837C5.81217 17.5837 2.41699 14.1885 2.41699 10.0003ZM18.0307 5.53066C18.3235 5.23776 18.3235 4.76289 18.0307 4.47C17.7378 4.1771 17.2629 4.1771 16.97 4.47L9.96615 11.4738L7.16087 9.01923C6.84914 8.74646 6.37532 8.77805 6.10256 9.08978C5.8298 9.40151 5.86139 9.87533 6.17311 10.1481L9.50645 13.0648C9.8036 13.3248 10.2515 13.3099 10.5307 13.0307L18.0307 5.53066Z"
                        fill="#00205C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3920_13490">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <p class="mb-0 px-2">
                    {{ $t("Subscribe.Assign user-level permissions") }}
                  </p>
                </div>
                <div class="flex items-center py-2">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3920_13490)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.41699 10.0003C2.41699 5.81217 5.81217 2.41699 10.0003 2.41699H12.5003C12.9145 2.41699 13.2503 2.08121 13.2503 1.66699C13.2503 1.25278 12.9145 0.916992 12.5003 0.916992H10.0003C4.98374 0.916992 0.916992 4.98374 0.916992 10.0003C0.916992 15.0169 4.98374 19.0837 10.0003 19.0837C15.0169 19.0837 19.0837 15.0169 19.0837 10.0003V9.58366C19.0837 9.16945 18.7479 8.83366 18.3337 8.83366C17.9194 8.83366 17.5837 9.16945 17.5837 9.58366V10.0003C17.5837 14.1885 14.1885 17.5837 10.0003 17.5837C5.81217 17.5837 2.41699 14.1885 2.41699 10.0003ZM18.0307 5.53066C18.3235 5.23776 18.3235 4.76289 18.0307 4.47C17.7378 4.1771 17.2629 4.1771 16.97 4.47L9.96615 11.4738L7.16087 9.01923C6.84914 8.74646 6.37532 8.77805 6.10256 9.08978C5.8298 9.40151 5.86139 9.87533 6.17311 10.1481L9.50645 13.0648C9.8036 13.3248 10.2515 13.3099 10.5307 13.0307L18.0307 5.53066Z"
                        fill="#00205C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3920_13490">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <p class="mb-0 px-2">
                    {{ $t("Subscribe.3 solution templates") }}
                  </p>
                </div>
                <!-- only plan 2  -->

                <div v-if="plan.plan_id == 2" class="flex items-center py-2">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3920_13490)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.41699 10.0003C2.41699 5.81217 5.81217 2.41699 10.0003 2.41699H12.5003C12.9145 2.41699 13.2503 2.08121 13.2503 1.66699C13.2503 1.25278 12.9145 0.916992 12.5003 0.916992H10.0003C4.98374 0.916992 0.916992 4.98374 0.916992 10.0003C0.916992 15.0169 4.98374 19.0837 10.0003 19.0837C15.0169 19.0837 19.0837 15.0169 19.0837 10.0003V9.58366C19.0837 9.16945 18.7479 8.83366 18.3337 8.83366C17.9194 8.83366 17.5837 9.16945 17.5837 9.58366V10.0003C17.5837 14.1885 14.1885 17.5837 10.0003 17.5837C5.81217 17.5837 2.41699 14.1885 2.41699 10.0003ZM18.0307 5.53066C18.3235 5.23776 18.3235 4.76289 18.0307 4.47C17.7378 4.1771 17.2629 4.1771 16.97 4.47L9.96615 11.4738L7.16087 9.01923C6.84914 8.74646 6.37532 8.77805 6.10256 9.08978C5.8298 9.40151 5.86139 9.87533 6.17311 10.1481L9.50645 13.0648C9.8036 13.3248 10.2515 13.3099 10.5307 13.0307L18.0307 5.53066Z"
                        fill="#00205C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3920_13490">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <p class="mb-0 px-2">
                    {{ $t("Subscribe.Dedicated onboarding specialist") }}
                  </p>
                </div>
                <div v-if="plan.plan_id == 2" class="flex items-center py-2">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3920_13490)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.41699 10.0003C2.41699 5.81217 5.81217 2.41699 10.0003 2.41699H12.5003C12.9145 2.41699 13.2503 2.08121 13.2503 1.66699C13.2503 1.25278 12.9145 0.916992 12.5003 0.916992H10.0003C4.98374 0.916992 0.916992 4.98374 0.916992 10.0003C0.916992 15.0169 4.98374 19.0837 10.0003 19.0837C15.0169 19.0837 19.0837 15.0169 19.0837 10.0003V9.58366C19.0837 9.16945 18.7479 8.83366 18.3337 8.83366C17.9194 8.83366 17.5837 9.16945 17.5837 9.58366V10.0003C17.5837 14.1885 14.1885 17.5837 10.0003 17.5837C5.81217 17.5837 2.41699 14.1885 2.41699 10.0003ZM18.0307 5.53066C18.3235 5.23776 18.3235 4.76289 18.0307 4.47C17.7378 4.1771 17.2629 4.1771 16.97 4.47L9.96615 11.4738L7.16087 9.01923C6.84914 8.74646 6.37532 8.77805 6.10256 9.08978C5.8298 9.40151 5.86139 9.87533 6.17311 10.1481L9.50645 13.0648C9.8036 13.3248 10.2515 13.3099 10.5307 13.0307L18.0307 5.53066Z"
                        fill="#00205C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3920_13490">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <p class="mb-0 px-2">{{ $t("Subscribe.Custom branding") }}</p>
                </div>
                <div v-if="plan.plan_id == 2" class="flex items-center py-2">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3920_13490)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.41699 10.0003C2.41699 5.81217 5.81217 2.41699 10.0003 2.41699H12.5003C12.9145 2.41699 13.2503 2.08121 13.2503 1.66699C13.2503 1.25278 12.9145 0.916992 12.5003 0.916992H10.0003C4.98374 0.916992 0.916992 4.98374 0.916992 10.0003C0.916992 15.0169 4.98374 19.0837 10.0003 19.0837C15.0169 19.0837 19.0837 15.0169 19.0837 10.0003V9.58366C19.0837 9.16945 18.7479 8.83366 18.3337 8.83366C17.9194 8.83366 17.5837 9.16945 17.5837 9.58366V10.0003C17.5837 14.1885 14.1885 17.5837 10.0003 17.5837C5.81217 17.5837 2.41699 14.1885 2.41699 10.0003ZM18.0307 5.53066C18.3235 5.23776 18.3235 4.76289 18.0307 4.47C17.7378 4.1771 17.2629 4.1771 16.97 4.47L9.96615 11.4738L7.16087 9.01923C6.84914 8.74646 6.37532 8.77805 6.10256 9.08978C5.8298 9.40151 5.86139 9.87533 6.17311 10.1481L9.50645 13.0648C9.8036 13.3248 10.2515 13.3099 10.5307 13.0307L18.0307 5.53066Z"
                        fill="#00205C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3920_13490">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <p class="mb-0 px-2">
                    {{ $t("Subscribe.Unlimited storage space") }}
                  </p>
                </div>
              </div>
            </b-col>
            <b-col
              :disabled="true"
              class="cursor-disabled h-100 custom-plan px-3 py-3"
              lg="3"
              md="6"
              sm="12"
            >
              <div class="flex content-center">
                <div class="flex content-center input-check pt-25px">
                  <input
                    class="radio-custom"
                    name="radio-group"
                    type="radio"
                    checked
                  />
                  <label class="radio-custom-label"></label>
                </div>
                <h1 class="startup-package-label">
                  {{ $t("Subscribe.Enterprise solutions") }}
                </h1>
              </div>
              <div>
                <div class="flex content-center">
                  <h1 class="price-number">850</h1>
                  <span class="month-number flex items-center">{{
                    $t("Subscribe./mo")
                  }}</span>
                </div>
                <p class="text-center egp-center py-2 mb-0">
                  {{ $t("Subscribe.EGP Per Month") }}
                </p>
                <p class="mb-0 text-center text-plan py-1post">
                  {{ $t("Subscribe.For Up to 10 Users") }}
                </p>
                <p class="mb-0 text-center text-plan py-2">
                  {{
                    $t("Subscribe.Ideal for established companies of all sizes")
                  }}
                </p>
              </div>
              <hr />
              <div class="">
                <!-- all plans -->
                <div class="flex items-center py-2">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3920_13490)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.41699 10.0003C2.41699 5.81217 5.81217 2.41699 10.0003 2.41699H12.5003C12.9145 2.41699 13.2503 2.08121 13.2503 1.66699C13.2503 1.25278 12.9145 0.916992 12.5003 0.916992H10.0003C4.98374 0.916992 0.916992 4.98374 0.916992 10.0003C0.916992 15.0169 4.98374 19.0837 10.0003 19.0837C15.0169 19.0837 19.0837 15.0169 19.0837 10.0003V9.58366C19.0837 9.16945 18.7479 8.83366 18.3337 8.83366C17.9194 8.83366 17.5837 9.16945 17.5837 9.58366V10.0003C17.5837 14.1885 14.1885 17.5837 10.0003 17.5837C5.81217 17.5837 2.41699 14.1885 2.41699 10.0003ZM18.0307 5.53066C18.3235 5.23776 18.3235 4.76289 18.0307 4.47C17.7378 4.1771 17.2629 4.1771 16.97 4.47L9.96615 11.4738L7.16087 9.01923C6.84914 8.74646 6.37532 8.77805 6.10256 9.08978C5.8298 9.40151 5.86139 9.87533 6.17311 10.1481L9.50645 13.0648C9.8036 13.3248 10.2515 13.3099 10.5307 13.0307L18.0307 5.53066Z"
                        fill="#00205C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3920_13490">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <p class="mb-0 px-2">{{ $t("Subscribe.Up to 200 users") }}</p>
                </div>
                <div class="flex items-center py-2">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3920_13490)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.41699 10.0003C2.41699 5.81217 5.81217 2.41699 10.0003 2.41699H12.5003C12.9145 2.41699 13.2503 2.08121 13.2503 1.66699C13.2503 1.25278 12.9145 0.916992 12.5003 0.916992H10.0003C4.98374 0.916992 0.916992 4.98374 0.916992 10.0003C0.916992 15.0169 4.98374 19.0837 10.0003 19.0837C15.0169 19.0837 19.0837 15.0169 19.0837 10.0003V9.58366C19.0837 9.16945 18.7479 8.83366 18.3337 8.83366C17.9194 8.83366 17.5837 9.16945 17.5837 9.58366V10.0003C17.5837 14.1885 14.1885 17.5837 10.0003 17.5837C5.81217 17.5837 2.41699 14.1885 2.41699 10.0003ZM18.0307 5.53066C18.3235 5.23776 18.3235 4.76289 18.0307 4.47C17.7378 4.1771 17.2629 4.1771 16.97 4.47L9.96615 11.4738L7.16087 9.01923C6.84914 8.74646 6.37532 8.77805 6.10256 9.08978C5.8298 9.40151 5.86139 9.87533 6.17311 10.1481L9.50645 13.0648C9.8036 13.3248 10.2515 13.3099 10.5307 13.0307L18.0307 5.53066Z"
                        fill="#00205C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3920_13490">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <p class="mb-0 px-2">
                    {{ $t("Subscribe.Up to 35 automatic data pushes") }}
                  </p>
                </div>
                <div class="flex items-center py-2">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3920_13490)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.41699 10.0003C2.41699 5.81217 5.81217 2.41699 10.0003 2.41699H12.5003C12.9145 2.41699 13.2503 2.08121 13.2503 1.66699C13.2503 1.25278 12.9145 0.916992 12.5003 0.916992H10.0003C4.98374 0.916992 0.916992 4.98374 0.916992 10.0003C0.916992 15.0169 4.98374 19.0837 10.0003 19.0837C15.0169 19.0837 19.0837 15.0169 19.0837 10.0003V9.58366C19.0837 9.16945 18.7479 8.83366 18.3337 8.83366C17.9194 8.83366 17.5837 9.16945 17.5837 9.58366V10.0003C17.5837 14.1885 14.1885 17.5837 10.0003 17.5837C5.81217 17.5837 2.41699 14.1885 2.41699 10.0003ZM18.0307 5.53066C18.3235 5.23776 18.3235 4.76289 18.0307 4.47C17.7378 4.1771 17.2629 4.1771 16.97 4.47L9.96615 11.4738L7.16087 9.01923C6.84914 8.74646 6.37532 8.77805 6.10256 9.08978C5.8298 9.40151 5.86139 9.87533 6.17311 10.1481L9.50645 13.0648C9.8036 13.3248 10.2515 13.3099 10.5307 13.0307L18.0307 5.53066Z"
                        fill="#00205C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3920_13490">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <p class="mb-0 px-2">
                    {{ $t("Subscribe.Limited access to T2D3 API") }}
                  </p>
                </div>
                <div class="flex items-center py-2">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3920_13490)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.41699 10.0003C2.41699 5.81217 5.81217 2.41699 10.0003 2.41699H12.5003C12.9145 2.41699 13.2503 2.08121 13.2503 1.66699C13.2503 1.25278 12.9145 0.916992 12.5003 0.916992H10.0003C4.98374 0.916992 0.916992 4.98374 0.916992 10.0003C0.916992 15.0169 4.98374 19.0837 10.0003 19.0837C15.0169 19.0837 19.0837 15.0169 19.0837 10.0003V9.58366C19.0837 9.16945 18.7479 8.83366 18.3337 8.83366C17.9194 8.83366 17.5837 9.16945 17.5837 9.58366V10.0003C17.5837 14.1885 14.1885 17.5837 10.0003 17.5837C5.81217 17.5837 2.41699 14.1885 2.41699 10.0003ZM18.0307 5.53066C18.3235 5.23776 18.3235 4.76289 18.0307 4.47C17.7378 4.1771 17.2629 4.1771 16.97 4.47L9.96615 11.4738L7.16087 9.01923C6.84914 8.74646 6.37532 8.77805 6.10256 9.08978C5.8298 9.40151 5.86139 9.87533 6.17311 10.1481L9.50645 13.0648C9.8036 13.3248 10.2515 13.3099 10.5307 13.0307L18.0307 5.53066Z"
                        fill="#00205C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3920_13490">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <p class="mb-0 px-2">
                    {{ $t("Subscribe.Assign user-level permissions") }}
                  </p>
                </div>
                <div class="flex items-center py-2">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3920_13490)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.41699 10.0003C2.41699 5.81217 5.81217 2.41699 10.0003 2.41699H12.5003C12.9145 2.41699 13.2503 2.08121 13.2503 1.66699C13.2503 1.25278 12.9145 0.916992 12.5003 0.916992H10.0003C4.98374 0.916992 0.916992 4.98374 0.916992 10.0003C0.916992 15.0169 4.98374 19.0837 10.0003 19.0837C15.0169 19.0837 19.0837 15.0169 19.0837 10.0003V9.58366C19.0837 9.16945 18.7479 8.83366 18.3337 8.83366C17.9194 8.83366 17.5837 9.16945 17.5837 9.58366V10.0003C17.5837 14.1885 14.1885 17.5837 10.0003 17.5837C5.81217 17.5837 2.41699 14.1885 2.41699 10.0003ZM18.0307 5.53066C18.3235 5.23776 18.3235 4.76289 18.0307 4.47C17.7378 4.1771 17.2629 4.1771 16.97 4.47L9.96615 11.4738L7.16087 9.01923C6.84914 8.74646 6.37532 8.77805 6.10256 9.08978C5.8298 9.40151 5.86139 9.87533 6.17311 10.1481L9.50645 13.0648C9.8036 13.3248 10.2515 13.3099 10.5307 13.0307L18.0307 5.53066Z"
                        fill="#00205C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3920_13490">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <p class="mb-0 px-2">
                    {{ $t("Subscribe.3 solution templates") }}
                  </p>
                </div>
              </div>
            </b-col>
          </b-row>
          <div class="flex content-center py-3">
            <button
              @click="closeEditSubscripePopUp()"
              class="btn form-red-button-outline pt-2"
            >
              {{ $t("Subscribe.Cancel") }}
            </button>
            <button @click="changePlan()" class="btn form-green-button pt-2" :disabled="plan_idd == current_plan || started">
              {{ $t("Subscribe.Save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import { required } from "vuelidate/lib/validators";
import arabicLocale from "@/mixins/arabicLocale.js";
import { AXIOS } from "../../../../axios";
import {errorToast, successToast, warningToast} from "@/helpers/toastifications";

import ls from "localstorage-slim";
ls.config.encrypt = true;

export default {
  name: "AddCardPopup",
  mixins: [arabicLocale],
  data() {
    return {
      full_name: "",
      card_number: "",
      cvv: "",
      expiryDate: "",
      month: "",
      year: "",
      status: "",
      months: [
        { month: "0" + 1 },
        { month: "0" + 2 },
        { month: "0" + 3 },
        { month: "0" + 4 },
        { month: "0" + 5 },
        { month: "0" + 6 },
        { month: "0" + 7 },
        { month: "0" + 8 },
        { month: "0" + 9 },
        { month: 10 },
        { month: 11 },
        { month: 12 },
      ],
      years: [],
      timeToExpire: 9,
      plans: [],
      checkplan: JSON.parse(localStorage.getItem("tierId")),
      current_plan: JSON.parse(localStorage.getItem("tierId")),
      planStatus: 1,
      plan_idd: "",
      started:true,
      xtenantt: localStorage.getItem("xtenant"),
      toast_success: false,
    };
  },
  mounted() {
    AXIOS.get("api/Plan/GetPlans").then((res) => {
      this.plans = res.data.plans;
      this.planStatus = res.data.Status;
    });
  },

  components: { BRow, BCol },

  watch: {
    card_number() {
      let realNumber = this.card_number.replace(/-/gi, "");
      let dashedNumber = realNumber.match(/.{1,4}/g);
      this.card_number = dashedNumber.join("-");
    },
  },

  validations: {
    full_name: { required },
    card_number: { required },
    cvv: { required },
    // expiryDate: { required }
    month: { required },
    year: { required },
  },

  methods: {
    successToast,
    warningToast,
    checkplann(plan) {
      this.started = false
      this.checkplan = plan;
      this.plan_idd = plan;
    },
    // close popup of  Subscribe PLan
    closeEditSubscripePopUp() {
      this.$store.dispatch("settings/closeEditSubscripePopup");
    },
    // change plan
    changePlan() {
      if(this.card_list.length > 0){
        if (this.checkplan == 2) {
          AXIOS.get("Setting/Subscription/calculatePlanChangeAmount").then(
              (res) => {
                console.log(res.data, "res");
                this.$swal({
                  title: this.$t("toast.Upgrade Plan"),
                  type: "warning",
                  html:
                      this.$t("toast.Are you sure you want to upgrade Plan and pay") +
                      " " +
                      res.data.total_amount +
                      "EGP" +
                      " " +
                      this.$t("toast.Money"),
                  desc: "You are ready to start!",
                  showCancelButton: true,
                  confirmButtonText: this.$t("toast.Yes"),
                  cancelButtonText: this.$t("toast.No"),
                  showCloseButton: true,
                  showLoaderOnConfirm: true,
                }).then((result) => {
                  if (result.value) {
                    let data = {
                      tier_id: this.checkplan,
                      xtenant: this.xtenantt,
                    };

                    AXIOS.post("Setting/Subscription/ChangePlan", data).then(
                        (res) => {
                          if (res.status == 200) {
                            this.closeEditSubscripePopUp();
                            this.successToast(
                                this.$t("toast.plan Changed Successfully")
                            );
                            localStorage.setItem("tierId", 2);
                            ls.set("code", "XA07");
                            setTimeout(function () {
                              location.reload();
                            }, 3000);
                          }
                        }
                    );
                  } else {
                  }
                });
              }
          );
        }
        if (this.checkplan == 1) {
          AXIOS.get("Setting/Subscription/calculatePlanChangeAmount").then(
              (res) => {
                console.log(res.data, "res");
                this.$swal({
                  title: this.$t("toast.Downgrade Plan"),
                  type: "warning",
                  html: this.$t("toast.Are you sure you want to downgrade Plan"),
                  showCancelButton: true,
                  confirmButtonText: this.$t("toast.Yes"),
                  cancelButtonText: this.$t("toast.No"),
                  showCloseButton: true,
                  showLoaderOnConfirm: true,
                }).then((result) => {
                  if (result.value) {
                    let data = {
                      tier_id: this.checkplan,
                      xtenant: this.xtenantt,
                    };

                    AXIOS.post("Setting/Subscription/ChangePlan", data).then(
                        (res) => {
                          if (res.status == 200) {
                            this.closeEditSubscripePopUp();
                            this.successToast(
                                this.$t(
                                    "toast.plan Changed Successfully from the Next Month"
                                )
                            );
                          }
                        }
                    );
                  }
                });
              }
          );
          x;
        }
      }else {
        warningToast(this.$t('toast.Please add card'));

      }

    },
  },
computed:{
  card_list() {
    return this.$store.state.settings.card_list;
  },
},
  created() {
    let yearNow = new Date().getFullYear();
    for (let i = yearNow; i < yearNow + this.timeToExpire; i++) {
      this.years.push({ year: i });
    }
  },
};
</script>

<style scoped>
@import url("../../../../assets/styles/popups.css");

.custom-select,
.form-control,
.desc-border,
.form-group >>> .vs__dropdown-toggle {
  border: 2px solid var(--label);
}

/* popup */
.edit-subscribe-Modal {
  display: none;
  position: fixed;
  z-index: 21;
  padding: 12px 0px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.7);
  justify-content: center;
}

.form-control,
.input-group-text {
  background: #ffffff;
  border: 1px solid #002873;
  border-radius: 4px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

.input-group-append {
  border-radius: 4px;
}

.card {
  padding: 0px 20px;
  background-color: #fff;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 16px 5px 20px 5px;
}

.modalHeader {
  background: #fff;
  color: #002873;
  padding: 16px 5px 0px 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
}

.modalHeader p {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #00263c;
}

.form-body {
  background: #f9f9f9;
  border-radius: 8px;
  padding: 24px 40px;
}

/* booking options popup */
.add-card-content {
  width: 100%;
  margin: auto;
  border-radius: 1rem;
}

/* form styles */
.label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #464e5f;
}

.form-check-label {
  color: #464e5f;
}

/* .form-blue-button {
      float: right;
      /* padding: 10px 20px; 
      height: 35px;
  } */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  /* appearance: none; */
}

.btns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-blue-button-outline,
.form-blue-button {
  /* padding: 20px 50px; */
  width: 191.26px;
  height: 45px;
}

.form-blue-button-outline {
  margin-right: 12px;
}

/* scroll bar */
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #002873;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media screen and (max-width: 575px) {
  .form-body {
    padding: 24px 10px;
  }
}

.ar-popup .input-group {
  flex-direction: row-reverse;
}

.ar-popup .form-check-inline {
  padding-right: 0px;
  padding-left: 0.75rem;
  margin-right: 0px;
  margin-left: 0.75rem;
}

.ar-popup .form-blue-button-outline {
  margin-right: 0px;
  margin-left: 12px;
}

.ar-popup .form-check-input {
  margin-right: 0px;
  margin-left: 0.3125rem;
}

/* edit subscribe */

.label-price {
  color: #002873;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.select-plan {
  color: #002873;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.startup-package {
  border-radius: 20px;
  border: 2px solid #002873;
  background: #f7f7f7;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.startup-package-label {
  color: #002873;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.54px;
}

.current-plan-span {
  color: #f5a800;
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.54px;
}

.custom-plan {
  border-radius: 20px;
  background: #f7f7f7;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  transition: transform 0.3s ease-in-out;
}

/* Add your hover effect */
.custom-plan:hover {
  transform: scale(1.04);
  /* You can adjust the scale factor as per your preference */
}

@media (min-width: 992px) {
  .col-lg-3 {
    flex: 0 0 30%;
    max-width: 75%;
  }
}

.price-number {
  color: #002873;
  text-align: center;
  /* H2 */
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.month-number {
  color: #002873;

  /* H5 */
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.42px;
}

.egp-center {
  color: #0065a1;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.text-plan {
  color: #002873;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.42px;
}

.radio-custom {
  opacity: 0;
  position: absolute;
}

.radio-custom-label {
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
  cursor: pointer;
}
.radio-custom-label-2 {
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
  cursor: pointer;
}

.checkbox-custom-label,
.radio-custom-label {
  position: relative;
}
.checkbox-custom-label,
.radio-custom-label-2 {
  position: relative;
}

.checkbox-custom + .checkbox-custom-label:before,
.radio-custom + .radio-custom-label:before {
  content: "";
  border: 1px solid #d4d7dc;
  display: inline-block;
  vertical-align: middle;
  width: 39px;
  height: 38px;
  margin-right: 0px;
  position: relative;
  text-align: center;
  margin-top: -5px;
}
.checkbox-custom + .checkbox-custom-label-2:before,
.radio-custom + .radio-custom-label-2:before {
  content: "";
  border: 1px solid #d4d7dc;
  display: inline-block;
  vertical-align: middle;
  width: 29px;
  height: 28px;
  margin-right: 0px;
  position: relative;
  text-align: center;
  margin-top: -5px;
}

.checkbox-custom:checked + .checkbox-custom-label:after {
  background: rebeccapurple;
  color: #fff;
  width: 10px;
  background: #fff;
  position: relative;
  border: 1px solid white;
  display: inline-block;
  vertical-align: middle;
  width: 39px;
  height: 38px;
  margin-right: 0px;
  margin-top: -5px;
}
.checkbox-custom:checked + .checkbox-custom-label-2:after {
  background: rebeccapurple;
  color: #fff;
  width: 10px;
  background: #fff;
  position: relative;
  border: 1px solid white;
  display: inline-block;
  vertical-align: middle;
  width: 39px;
  height: 38px;
  margin-right: 0px;
  margin-top: -5px;
}

.radio-custom + .radio-custom-label:before {
  border-radius: 50%;
}
.radio-custom + .radio-custom-label-2:before {
  border-radius: 50%;
}

.radio-custom:checked + .radio-custom-label:before {
  font-family: "FontAwesome";
  color: #bbb;
  display: flex;
  justify-content: center;
}
.radio-custom:checked + .radio-custom-label-2:before {
  font-family: "FontAwesome";
  color: #bbb;
  display: flex;
  justify-content: center;
}

.checkbox-custom:focus + .checkbox-custom-label,
.radio-custom:focus + .radio-custom-label {
}
.checkbox-custom:focus + .checkbox-custom-label,
.radio-custom:focus + .radio-custom-label-2 {
}

.input-check {
  position: absolute;
  top: -28px;
}

.pointer {
  cursor: pointer;
}
.h-100 {
  height: 100%;
}
.custom-title-class {
  font-size: 14px !important; /* Adjust the font size as needed */
}
.absolute {
  position: absolute;
}
</style>
