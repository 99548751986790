import Vue from "vue";
import Vuex from "vuex";
import defineAbilityFor from "@/casl/defineAdminAbility";
import ls from "localstorage-slim";
ls.config.encrypt = true;

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    layout: localStorage.getItem("layout") || "dashboard-layout",
    is_sidebar_closed: false,
    companyLogo: "",
    companyName: localStorage.getItem("companyName"),
    ability: defineAbilityFor(ls.get("ability")),
  },
  mutations: {
    SET_LAYOUT(state, payload) {
      state.layout = payload;
    },

    set_is_sidebar_closed: (state, data) => {
      state.is_sidebar_closed = data;
    },
  },
  getters: {
    layout(state) {
      return state.layout;
    },

    is_sidebar_closed: (state) => state.is_sidebar_closed,

    ability: (state) => state.ability,
  },
  actions: {
    is_sidebar_closed_action: ({ commit }, data) => {
      commit("set_is_sidebar_closed", data);
    },
  },
  modules: {},
});
