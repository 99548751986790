export default {
  computed: {
    isArabicLocale() {
      return this.$i18n.locale === "ar";
    },
    commonBorderClass() {
      return this.isArabicLocale ? "common-border-left" : "common-border-right";
    },
    infoBorderClass() {
      return this.isArabicLocale ? "info-border-left" : "info-border-right";
    },
  },
  methods: {
    showPrices(currency, price) {
      if (!currency) {
        return price;
      }
      return this.isArabicLocale
        ? `${price} ${currency}`
        : `${currency} ${price}`;
    },
    generateStatus(module, status) {
      return this.isArabicLocale
        ? this.$t(`${module}.${status}`)
        : status.charAt(0).toUpperCase() + status.slice(1);
    },
  },
};
