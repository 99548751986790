import store from "../users/store";
import defineAbilityFor from "@/casl/defineAdminAbility";
import ls from "localstorage-slim";

ls.config.encrypt = true;

const ability = defineAbilityFor(ls.get("perToken"));

const moduleRoute = {
  path: "/projects",
  component: () =>
    import(
      /* webpackChunkName: "projects-module", webpackPrefetch: true */ "./Module.vue"
    ),
  children: [
    {
      path: "/",
      component: () =>
        import(
          /* webpackChunkName: "projects-home", webpackPrefetch: true */ "./views/Home.vue"
        ),
    },

    {
      path: "/projects/:id",
      component: () =>
        import(
          /* webpackChunkName: "projects-view", webpackPrefetch: true */ "./views/ProjectView.vue"
        ),
    },
  ],
  beforeEnter: (to, from, next) => {
    if (
      store.state.token &&
      (ls.get("code") === "XA07" || ability.can("read", "Projects"))
    ) {
      localStorage.setItem("layout", "dashboard-layout");
      next();
      return;
    } else if (ls.get("code") !== "XA07" || !ability.can("read", "Projects")) {
      localStorage.setItem("layout", "dashboard-layout");
      next("/notAuthorizedForProject");
      return;
    }
    localStorage.setItem("layout", "landing-layout");
    next("/login");
  },
};

export default (router) => {
  router.addRoute(moduleRoute);
};
