import { AXIOS } from "../../../axios";
import ls from "localstorage-slim";
import router from "../../../router";
import {
  successToast,
  warningToast,
  errorToast,
} from "@/helpers/toastifications";

ls.config.encrypt = true;

export default {
  namespaced: true,
  state: {
    members: [],
    loading: true,
    memberById: {},
    viewLoading: true,
    permissionsData: [
      {
        module: "Admin",
        create: true,
        read: false,
        update: false,
        delete: false,
      },
      {
        module: "Staff",
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      {
        module: "Author",
        create: true,
        read: false,
        update: true,
        delete: false,
      },
      {
        module: "Contributor",
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      {
        module: "User",
        create: false,
        read: false,
        update: false,
        delete: true,
      },
    ],
  },
  getters: {
    members: (state) => state.members,
    loading: (state) => state.loading,
    memberById: (state) => state.memberById,
    viewLoading: (state) => state.viewLoading,
    permissionsData: (state) => state.permissionsData,
  },
  mutations: {
    set_members: (state, data) => {
      state.members = data;
    },

    set_loading: (state, data) => {
      state.loading = data;
    },

    set_memberById: (state, data) => {
      state.memberById = data;
    },

    set_viewLoading: (state, data) => {
      state.viewLoading = data;
    },

    set_permissionsData: (state, data) => {
      state.permissionsData = data;
    },
  },
  actions: {
    closeAddMemberPopup() {
      document.getElementById("addMemberModal").style.display = "none";
    },

    openAddMemberPopup() {
      document.getElementById("addMemberModal").style.display = "flex";
    },

    openEditMemberPopup() {
      document.getElementById("editMemberModal").style.display = "flex";
    },

    closeEditMemberPopup() {
      document.getElementById("editMemberModal").style.display = "none";
    },

    add_member_action: ({ dispatch }, payload) => {
      return AXIOS.post("Setting/Members/AddMember", {
        email: payload.email,
        first_name: payload.first_name,
        last_name: payload.last_name,
        role_id: payload.role_id,
      })
        .then((res) => {
          if (res.status === 200) {
            dispatch("get_members_action");
            dispatch("closeAddMemberPopup");
          }
          return res;
        })
        .catch((err) => {
          return err;
        });
    },

    editMemberAction: ({ dispatch }, payload) => {
      return AXIOS.post(`Setting/Members/UpdatePermission/${payload.id}`, {
        email: payload.email,
        first_name: payload.first_name,
        last_name: payload.last_name,
        role_id: payload.role_id,
      })
        .then((res) => {
          if (res.status === 200) {
            dispatch("get_members_action");
            dispatch("closeEditMemberPopup");
          }
          return res;
        })
        .catch((err) => {
          if (err.status == 409) {
            return err;
          }
          return err;
        });
    },

    get_members_action: ({ commit }) => {
      commit("set_loading", true);
      AXIOS.post("Setting/Members/GetMember", {})
        .then((res) => {
          commit("set_members", res.data.MemberList);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_loading", false);
        });
    },

    get_memberById_action: ({ commit }, id) => {
      AXIOS.post(`Setting/Members/GetMemberID/${id}`)
        .then((res) => {
          commit("set_memberById", res.data);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_viewLoading", false);
        });
    },

    set_permissionsData_action: ({ commit }, payload) => {
      commit("set_permissionsData", payload);
    },

    update_permissions_action: ({ dispatch }, payload) => {
      AXIOS.post(`Setting/Members/UpdatePermission/${payload.id}`, {
        permissions: payload.permissions,
      })
        .then((res) => {
          successToast("Permissions updated successfully!");
        })
        .catch((err) => {
          err.message;
        });
    },
  },
};
