// import '@babel/polyfill'
// import 'mutationobserver-shim'
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./plugins/i18n";
import Vuelidate from "vuelidate";
import vSelect from "vue-select";
import VueApexCharts from "vue-apexcharts";

import "vue-select/dist/vue-select.css";
import debounce from "lodash/debounce";

import TrialCounterBar from "./components/TrialCounterBar.vue";
// import VueApexCharts from 'vue-apexcharts';
// import VueHtmlToPaper from 'vue-html-to-paper';
import salesModule from "./modules/sales";
import quotesModule from "./modules/quotes";
import customersModule from "./modules/customers";
import productsModule from "./modules/products";
import expensesModule from "./modules/expenses";
import billModule from "./modules/bill";
import VueDatepickerUi from "vue-datepicker-ui";
import "vue-datepicker-ui/lib/vuedatepickerui.css";
Vue.component("Datepicker", VueDatepickerUi);

// import payrollModule from './modules/payroll'
import vendorsModule from "./modules/vendors";
import reportsModule from "./modules/reports";
import taxesModule from "./modules/taxes";
import chartsOfAccountsModule from "./modules/chartsOfAccounts";
import usersModule from "./modules/users";
import landingModule from "./modules/landing";
import dashboardv2Module from "./modules/dashboardv2";
import settingsModule from "./modules/settings";
import projectsModule from "./modules/project";
import membersModule from "./modules/members";
import blogsModule from "./modules/blogs";

import ls from "localstorage-slim";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

(async () => {
  let locale;

  if (ls.get("locale") && ls.get("locale") === "en") {
    locale = await import("element-ui/lib/locale/lang/en");
  } else {
    locale = await import("element-ui/lib/locale/lang/ar");
  }

  Vue.use(ElementUI, { locale: locale.default });

  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");
})();

import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/src/jquery.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "@fortawesome/fontawesome-free/css/all.css";
ls.config.encrypt = true;
import "bootstrap/dist/css/bootstrap.min.css";
import { BPopover } from "bootstrap-vue";
Vue.component("b-popover", BPopover);
import { ModalPlugin } from "bootstrap-vue";
Vue.use(ModalPlugin);

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

// interceptor
import { AXIOS } from "./axios";

import { errorHandler } from "./helpers/errorHandler";

AXIOS.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => errorHandler(error)
);

import VueSweetalert2 from "vue-sweetalert2";

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";

const swoptions = {
  confirmButtonColor: "#00205C",
  cancelButtonColor: "#E74C3C",
};

Vue.use(VueSweetalert2, swoptions);

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

import { registerModules } from "./register-modules";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

/* import font awesome icon component */
library.add(fas);
library.add(far);
library.add(fab);
/* add icons to the library */

// bug
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import BugsnagPerformance from "@bugsnag/browser-performance";

Bugsnag.start({
  apiKey: "205058af2f8c4f19de6bdc0554c882b1",
  plugins: [new BugsnagPluginVue()],
});
BugsnagPerformance.start({ apiKey: "205058af2f8c4f19de6bdc0554c882b1" });
const bugsnagVue = Bugsnag.getPlugin("vue");

Bugsnag.notify(new Error("Test error09"));

/* add font awesome icon component */
Vue.component("font-awesome-icon", FontAwesomeIcon);

const options = {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
};

Vue.use(Toast, options);
bugsnagVue.installVueErrorHandler(Vue);

Vue.component("v-select", vSelect);
Vue.component("apexchart", VueApexCharts);

Vue.component("trial-counter-bar", TrialCounterBar);

Vue.component("debounce", debounce);

// Vue.use(VueHtmlToPaper, printOptions);

registerModules({
  sales: salesModule,
  quotes: quotesModule,
  customers: customersModule,
  products: productsModule,
  expenses: expensesModule,
  bill: billModule,
  // payroll: payrollModule,
  vendors: vendorsModule,
  reports: reportsModule,
  taxes: taxesModule,
  chartsOfAccounts: chartsOfAccountsModule,
  users: usersModule,
  landing: landingModule,
  dashboard: dashboardv2Module,
  settings: settingsModule,
  projects: projectsModule,
  members: membersModule,
  blogs: blogsModule,
});

Vue.config.productionTip = false;

Vue.use(Vuelidate);
