import store from "../users/store";

const moduleRoute = {
  path: "/chartsOfAccounts",
  component: () =>
    import(
      /* webpackChunkName: "chartsOfAccounts-module", webpackPrefetch: true */ "./Module.vue"
    ),
  children: [
    {
      path: "/",
      component: () =>
        import(
          /* webpackChunkName: "chartsOfAccounts-home", webpackPrefetch: true */ "./views/Home.vue"
        ),
    },

    {
      path: "/chartsOfAccounts/reconcile",
      component: () =>
        import(
          /* webpackChunkName: "reconcile-home", webpackPrefetch: true */ "./views/Reconcile.vue"
        ),
    },

    {
      path: `/chartsOfAccounts/:id`,
      component: () =>
        import(
          /* webpackChunkName: "chartsOfAccounts-view", webpackPrefetch: true */ "./views/CoaView.vue"
        ),
    },
  ],
  beforeEnter: (to, from, next) => {
    if (store.state.token) {
      localStorage.setItem("layout", "dashboard-layout");
      next();
      return;
    }
    localStorage.setItem("layout", "landing-layout");
    next("/login");
  },
};

export default (router) => {
  router.addRoute(moduleRoute);
};
