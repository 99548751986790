import { AXIOS } from "../../../axios";
import { successToast } from "@/helpers/toastifications";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    vendors: [],
    terms: [],
    // chartsOfAccounts: [],
    billing_accounts: [],
    loading: true,
    vendorStats: {},
    billsVsVendors: {},
    vendorById: {},
    viewLoading: true,
    autoSelectedVendor: {},
    showTable: false,
    addingVendor: false,
  },
  getters: {
    terms: (state) => state.terms,
    // chartsOfAccounts: state => state.chartsOfAccounts,
    vendors: (state) => state.vendors,
    billing_accounts: (state) => state.billing_accounts,
    loading: (state) => state.loading,
    vendorStats: (state) => state.vendorStats,
    billsVsVendors: (state) => state.billsVsVendors,
    vendorById: (state) => state.vendorById,
    viewLoading: (state) => state.viewLoading,
    autoSelectedVendor: (state) => state.autoSelectedVendor,
    showTable: (state) => state.showTable,
    addingVendor: (state) => state.addingVendor,
  },
  mutations: {
    setTerms: (state, data) => {
      state.terms = data;
    },

    set_addingVendor: (state, data) => {
      state.addingVendor = data;
    },

    setVendors: (state, data) => {
      state.vendors = data;
    },

    set_billing_accounts: (state, data) => {
      state.billing_accounts = data;
    },

    set_loading: (state, data) => {
      state.loading = data;
    },

    set_vendorStats: (state, data) => {
      state.vendorStats = data;
    },

    set_billsVsVendors: (state, data) => {
      state.billsVsVendors = data;
    },

    set_vendorById: (state, data) => {
      state.vendorById = data;
    },

    set_viewLoading: (state, data) => {
      state.viewLoading = data;
    },

    set_autoSelectedVendor: (state, data) => {
      state.autoSelectedVendor = data;
    },

    set_showTable: (state, data) => {
      state.showTable = data;
    },
  },
  actions: {
    openAddSupplierPopup() {
      const addSupplierModal = document.getElementById("addSupplierModal");
      addSupplierModal.style.display = "flex";
    },

    closeAddSupplierPopup() {
      const addSupplierModal = document.getElementById("addSupplierModal");
      addSupplierModal.style.display = "none";
    },

    getVendorsAction: ({ commit }, search) => {
      AXIOS.post("Vendor/ListVendor", { search: search })
        .then((res) => {
          commit("setVendors", res.data.Vendors);
          commit("set_showTable", res.data.ShowTable);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_loading", false);
        });
    },

    getTermsAction: ({ commit }) => {
      AXIOS.get(`Forms/GetTerms`)
        .then((res) => {
          commit("setTerms", res.data);
        })
        .catch((err) => {
          err.message;
        });
    },

    // chartsOfAccountsAction: ({commit}) => {
    //     AXIOS.get(`COA/GetMainChart`,)
    //     .then(res => {
    //         commit('setChartsOfAccounts', res.data.MainChart)
    //     }).catch(err => {
    //         (err.message);
    //     })
    // },

    addVendorAction: ({ dispatch, commit, state }, payload) => {
      commit("set_addingVendor", true);
      return AXIOS.post("Vendor/AddVendor", {
        first_name: payload.firstName,
        middle_name: payload.middleName,
        last_name: payload.lastName,
        company_name: payload.company,
        Display_name: payload.displayName,
        email: payload.email,
        phone: payload.phone,
        mobile: payload.mobile,
        other: payload.other,
        website: payload.website,
        opening_balance: payload.openingBalance,
        foreign_opening_balance: payload.foreign_opening_balance,
        tax: payload.tax,
        address: payload.address,
        city: payload.city,
        state: payload.state,
        postal_code: payload.postal_code,
        country: payload.country,
        notes: payload.notes,
        terms_id: payload.term_id,
        billing: payload.billing_rate,
        main_charts_id: payload.account_no_id,
        as_of: payload.asOf,
        bill_chart_id: payload.bill_chart_id,
        currency_id: payload.currency_id,
      })
        .then((res) => {
          if (res.status === 200) {
            commit("set_addingVendor", false);
            dispatch("getVendorsAction");
            setTimeout(() => {
              commit(
                "set_autoSelectedVendor",
                state.vendors[state.vendors.length - 1]
              );
            }, 1000);
            dispatch("closeAddSupplierPopup");
          }
          return res;
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_addingVendor", false);
        });
    },

    editVendorAction: ({ dispatch }, payload) => {
      AXIOS.post(`Vendor/EditVendor/${payload.id}`, {
        first_name: payload.firstName,
        middle_name: payload.middleName,
        last_name: payload.lastName,
        company_name: payload.company,
        display_name: payload.displayName,
        email: payload.email,
        phone: payload.phone,
        mobile: payload.mobile,
        other: payload.other,
        website: payload.website,
        opening_balance: payload.openingBalance,
        foreign_opening_balance: payload.foreign_opening_balance,
        address: payload.address,
        city: payload.city,
        state: payload.state,
        postal_code: payload.postal_code,
        country: payload.country,
        notes: payload.notes,
        terms_id: payload.term_id,
        billing: payload.billing_rate,
        main_charts_id: payload.account_no_id,
        as_of: payload.asOf,
        currency_id: payload.currency_id,
      })
        .then((res) => {
          // (res.data);
          if (res.status === 200) {
            // Vue.$toast("Vendor edited successfully!", {
            //     timeout: 5000
            // });
            successToast(this.$t("toast.edited successfully!"));
          }
        })
        .catch((err) => {
          err.message;
        });
    },

    get_billing_accounts_action: ({ commit }) => {
      AXIOS.get("Forms/GetApCoaForVendors")
        .then((res) => {
          commit("set_billing_accounts", res.data.COAList);
        })
        .catch((err) => {
          err.message;
        });
    },

    get_vendorStats_action: ({ commit }) => {
      AXIOS.post("Vendor/GetVendorState", {})
        .then((res) => {
          commit("set_vendorStats", res.data);
        })
        .catch((err) => {
          err.message;
        });
    },

    get_billsVsVendors_action: ({ commit }) => {
      AXIOS.post("Vendor/GetBillVsVendor", {})
        .then((res) => {
          commit("set_billsVsVendors", res.data);
        })
        .catch((err) => {
          err.message;
        });
    },

    get_vendorById_action: ({ commit }, id) => {
      AXIOS.post(`Vendor/VendorDetails/${id}`, {})
        .then((res) => {
          commit("set_vendorById", res.data.CustomerDetails);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_viewLoading", false);
        });
    },

    deleteVendorAction: ({ dispatch }, id) => {
      AXIOS.get(`Vendor/DeleteVendor/${id}`)
        .then((res) => {
          // (res);
        })
        .catch((err) => {
          err.message;
        });
    },
  },
  modules: {},
};
