import Vue from "vue";
import { AXIOS } from "../../../axios";
import {
  successToast,
  warningToast,
  errorToast,
} from "@/helpers/toastifications";
import store from "@/store/index.js";
import app from "@/env";
import axios from "axios";

// staging baseUrl
// const baseURL = '/';

// production baseUrl
const baseURL = app.baseURL;
export default {
  data() {
    return {
      app,
    };
  },
  namespaced: true,
  state: {
    monthly: "mo",
  },
  getters: {
    monthly: (state) => state.monthly,
  },
  mutations: {
    set_monthly: (state, data) => {
      state.monthly = data;
    },
  },
  actions: {
    openSubscriptionPopup() {
      const supportModal = document.getElementById("supportModal");
      supportModal.style.display = "block";
    },

    openPlansPopup() {
      const plansModal = document.getElementById("plansModal");
      plansModal.style.display = "block";
      plansModal;
    },

    openPlansModel() {
      const plansModalTrial = document.getElementById("plansModalTrial");
      plansModalTrial.style.display = "block";
      plansModalTrial;
    },

    closePlansPopup() {
      const plansModalTrial = document.getElementById("plansModalTrial");
      plansModalTrial.style.display = "none";
    },

    closeSubscriptionPopup() {
      const supportModal = document.getElementById("supportModal");
      supportModal.style.display = "none";
    },

    change_radio_action: ({ commit }, payload) => {
      commit("set_monthly", payload);
    },

    contactusAction: ({ commit }, payload) => {
      axios
        .post(`${this.app.baseURL}Ticket/CreateTicket`, {
          full_name: payload.full_name,
          email: payload.email,
          message: payload.message,
        })
        .then((res) => {
          if (res.status === 200) {
            // Vue.$toast("Message sent successfully!", {
            //     timeout: 5000
            // });
            successToast("Message sent successfully!");
          }
        })
        .catch((err) => {
          err.message;
        });
    },

    addCardAction: ({ commit, dispatch }, payload) => {
      try {
        AXIOS.post("PaymentMethod/SaveCardToken", {
          card_holder_name: payload.full_name,
          card_number: payload.card_number,
          cvv: payload.cvv,
          expiry_month: payload.month,
          expiry_year: payload.year,
          status: payload.status,
          xTenant: payload.xTenant,
        }).then((res) => {
          if (res.status === 200) {
            successToast("Payment Has Been Processed Successfully!");

            setTimeout(() => {
              // store.dispatch('users/logoutAction');
              // window.location.replace('/login');
            }, 2000);
          } else if (res.status === 409) {
            warningToast(
              "Card Has Been Rejected. Please Check Card Information"
            );
          }
        });
      } catch (error) {
        error.message;
      }
    },
  },
  modules: {},
};
