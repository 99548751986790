import store from "../users/store";

const moduleRoute = {
  path: "/taxes",
  component: () => import("./Module.vue"),
  children: [
    {
      path: "/",
      component: () =>
        import(
          /* webpackChunkName: "taxes-home", webpackPrefetch: true */ "./views/Home.vue"
        ),
    },
    {
      path: "/recordPayment",
      component: () =>
        import(
          /* webpackChunkName: "recordPayment", webpackPrefetch: true */ "./views/RecordPayment.vue"
        ),
    },
    {
      path: "/addTaxReconcil",
      component: () =>
        import(
          /* webpackChunkName: "addTaxReconcil", webpackPrefetch: true */ "./views/AddTaxReconcil.vue"
        ),
    },
    {
      path: "/detailsReport",
      component: () =>
        import(
          /* webpackChunkName: "detailsReport", webpackPrefetch: true */ "./views/DetailsReport.vue"
        ),
    },
  ],
  beforeEnter: (to, from, next) => {
    if (store.state.token) {
      localStorage.setItem("layout", "dashboard-layout");
      next();
      return;
    }
    localStorage.setItem("layout", "landing-layout");
    next("/login");
  },
};

export default (router) => {
  router.addRoute(moduleRoute);
};
