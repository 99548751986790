import axios from "axios";
import { AXIOS } from "../../../axios";

export default {
  namespaced: true,
  state: {
    reports: [],
    displayList: false,
    report: {},
    journalEntries: [],
    attachments: [],
    journalLoading: true,
    pnl_loading: true,
    generalLedger: {},
    generalLedgerLoading: true,
    trialBalance: {},
    trialBalanceLoading: true,
    balanceSheet: {},
    balanceSheet_loading: true,
    reportName: "",
    bills: [],
    billsReport_loading: true,
    vat:[],
    vatsReport_loading: true,

    VatExpenses:[],
    vatsExpenseReport_loading: true,
    journalEntriesView: [],
  },
  getters: {
    journalEntriesView: (state) => state.journalEntriesView,

    displayList: (state) => state.displayList,

    report: (state) => state.report,

    journalEntries: (state) => state.journalEntries,

    attachments: (state) => state.attachments,

    journalLoading: (state) => state.journalLoading,

    pnl_loading: (state) => state.pnl_loading,

    generalLedger: (state) => state.generalLedger,

    generalLedgerLoading: (state) => state.generalLedgerLoading,

    trialBalance: (state) => state.trialBalance,

    trialBalanceLoading: (state) => state.trialBalanceLoading,

    balanceSheet: (state) => state.balanceSheet,

    balanceSheet_loading: (state) => state.balanceSheet_loading,

    reportName: (state) => state.reportName,

    bills: (state) => state.bills,
  },
  mutations: {
    set_JEView: (state, data) => {
      state.journalEntriesView = data;
    },

    set_displayList: (state, data) => {
      state.displayList = data;
    },

    set_report: (state, data) => {
      state.report = data;
    },

    set_journalEntries: (state, data) => {
      state.journalEntries = data;
    },
    set_Attachments: (state, data) => {
      state.attachments = data;
    },

    set_journalLoading: (state, data) => {
      state.journalLoading = data;
    },

    set_pnl_loading: (state, data) => {
      state.pnl_loading = data;
    },

    set_generalLedger: (state, data) => {
      state.generalLedger = data;
    },

    set_generalLedgerLoading: (state, data) => {
      state.generalLedgerLoading = data;
    },

    set_trialBalance: (state, data) => {
      state.trialBalance = data;
    },

    set_trialBalanceLoading: (state, data) => {
      state.trialBalanceLoading = data;
    },

    set_balanceSheet: (state, data) => {
      state.balanceSheet = data;
    },

    set_balanceSheet_loading_action: (state, data) => {
      state.balanceSheet_loading = data;
    },

    set_reportName: (state, data) => {
      state.reportName = data;
    },

    set_billsReport: (state, data) => {
      state.bills = data;
    },

    set_billsReport_loading_action: (state, data) => {
      state.billsReport_loading = data;
    },

    set_VatReport: (state, data) => {
      state.vat = data;
    },

    set_vat_report_loading_action: (state, data) => {
      state.vatsReport_loading = data;
    },

    set_VatExpenseReport: (state, data) => {
      state.VatExpenses = data;
    },

    set_vat_expense_report_loading_action: (state, data) => {
      state.vatsExpenseReport_loading = data;
    },
  },
  actions: {
    getJEView: ({ commit }, id) => {
      AXIOS.post(`JournalEntry/GetJournalEntryByNum/${id}`, {})
        .then((res) => {
          id;
          // (res.data);
          commit("set_JEView", res.data);
          commit("set_Attachments", res.data.files);
        })
        .catch((err) => {
          err.message;
        });
    },

    set_displayList_action: ({ commit }, data) => {
      commit("set_displayList", data);
    },

    run_pnl_action: ({ commit }, payload) => {
      AXIOS.post("Reports/ProfitAndLoss", payload)
        .then((res) => {
          commit("set_report", res.data);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_pnl_loading", false);
        });
    },

    get_journalEntries_action: ({ commit }, data) => {
      commit("set_journalLoading", true);

      AXIOS.post("Reports/GetJournalEntry", data)
        .then((res) => {
          commit("set_journalEntries", res.data.JournalEntry);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_journalLoading", false);
        });
    },

    get_generalLedger_action: ({ commit }, search) => {
      AXIOS.post("Reports/GeneralLedgerReport", search)
        .then((res) => {
          commit("set_generalLedger", res.data.data);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_generalLedgerLoading", false);
        });
    },

    get_trialBalance_action: ({ commit }, search) => {
      AXIOS.post("Reports/TrailBalanceReport", search)
        .then((res) => {
          commit("set_trialBalance", res.data);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_trialBalanceLoading", false);
        });
    },

    get_balanceSheet_action: ({ commit }, search) => {
      AXIOS.post("Reports/BalanceSheet", search)
        .then((res) => {
          commit("set_balanceSheet", res.data);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_balanceSheet_loading_action", false);
        });
    },

    set_reportName_action: ({ commit }, data) => {
      commit("set_reportName", data);
    },

    get_bills_report: ({ commit }, search) => {
      AXIOS.post("Bill/BillsReport", search)
        .then((res) => {
          commit("set_billsReport", res.data);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_billsReport_loading_action", false);
        });
    },

    get_vat_expense_report: ({ commit }, search) => {
      AXIOS.post("Reports/VatReport?type=expense", search)
        .then((res) => {
          commit("set_VatExpenseReport", res.data);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_vat_expense_report_loading_action", false);
        });
    },
    get_vat_report: ({ commit }, search) => {
      AXIOS.post("Reports/VatReport?type=invoice", search)
        .then((res) => {
          commit("set_VatReport", res.data);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_vat_report_loading_action", false);
        });
    },
  },
  modules: {},
};
