<template>
  <div id="errorToast" :class="{ 'ar-popup': isArabicLocale, show: status }">
    <div style="position: relative">
      <div class="d-flex align-items-center">
        <img
          @click="hide"
          width="20"
          height="20"
          src="../../assets/toasts/error.webp"
          alt=""
          class="mr-2 cursor"
        />
        <div>
          <h5>{{ $t("toast.Error") }}</h5>
          <p class="m-0" id="errorText" v-if="msg !== undefined">
            {{ $t(`${msg}`) }}
          </p>
          <p class="m-0" id="errorText" v-else>
            {{ $t("toast.Something went wrong") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import arabicLocale from "@/mixins/arabicLocale.js";

export default {
  mixins: [arabicLocale],
  name: "ErrorToast",
  props: ["msg", "status"],
  methods: {
    hide() {
      var toast = document.getElementById("errorToast");
      toast.classList.remove("show");
    },
  },
};
</script>

<style scoped>
#errorToast {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background: #fcedea;
  border: 1px solid #e71d07;
  color: #333c48;
  border-radius: 10px;
  padding: 16px;
  position: fixed;
  z-index: 100000000000;
  left: 50%;
  top: 30px;
  font-size: 16px;
}

#errorToast.show {
  visibility: visible;
  z-index: 100000000000;

  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    top: 30px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    top: 30px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}
</style>
