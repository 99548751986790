import store from "../users/store";

const moduleRoute = {
  path: "/products",
  component: () =>
    import(
      /* webpackChunkName: "products-module", webpackPrefetch: true */ "./Module.vue"
    ),
  children: [
    {
      path: "/",
      component: () =>
        import(
          /* webpackChunkName: "products-home", webpackPrefetch: true */ "./views/Home.vue"
        ),
    },
    {
      path: "/products/:id",
      component: () =>
        import(
          /* webpackChunkName: "products-view", webpackPrefetch: true */ "./views/ProductView.vue"
        ),
    },
  ],
  beforeEnter: (to, from, next) => {
    if (store.state.token) {
      localStorage.setItem("layout", "dashboard-layout");
      next();
      return;
    }
    localStorage.setItem("layout", "landing-layout");
    next("/login");
  },
};

export default (router) => {
  router.addRoute(moduleRoute);
};
