import { AXIOS } from "../../../axios";
import Vue from "vue";
import {
  successToast,
  warningToast,
  errorToast,
} from "@/helpers/toastifications";

import arabicLocale from "../../../mixins/arabicLocale";

export default {
  namespaced: true,
  state: {
    taxes: [],
    tax_types: [],
    taxsList: [],
    newTaxsList: [],
    taxCategories: [],
    autoSelectedTax: {},
    addingTax: false,
    currentLocale: "en",
  },
  getters: {
    taxes: (state) => state.taxes,
    tax_types: (state) => state.tax_types,
    taxsList: (state) => state.taxsList,
    autoSelectedTax: (state) => state.autoSelectedTax,
    addingTax: (state) => state.addingTax,
    taxCategories: (state) => state.taxCategories,
    currentLocale: (state) => state.currentLocale,
    newTaxsList: (state) => state.newTaxsList,
  },
  mutations: {
    setTaxes: (state, data) => {
      state.taxes = data;
    },

    setTaxTypes: (state, data) => {
      state.tax_types = data;
    },

    set_taxsList: (state, data) => {
      state.taxsList = data;
    },

    set_new_taxsList: (state, data) => {
      state.newTaxsList = data;
    },

    taxCategories: (state, data) => {
      state.taxCategories = data;
    },

    closeAddTaxPopup() {
      const addTaxModal = document.getElementById("addTaxModal");
      if(addTaxModal){
        addTaxModal.style.display = "none";

      }
    },

    getTaxsAction: ({ commit }) => {
      AXIOS.get("Tax/GetTax")
        .then((res) => {
          commit("setTaxes", res.data.Tax);
        })
        .catch((err) => {
          err.message;
        });
    },

    accountTypeAction: async ({ commit }) => {
      try {
        const res = await AXIOS.get(`COA/GetMainChart`);
        commit("setCoa", res.data.MainChart);
      } catch (error) {}
    },

    getTaxTypesAction: ({ commit }) => {
      AXIOS.get("Tax/GetTaxType")
        .then((res) => {
          commit("setTaxTypes", res.data.MyExpenses);
        })
        .catch((err) => {});
    },

    get_taxsList_action: ({ commit }) => {
      AXIOS.get("Forms/GetMyTaxesList")
        .then((res) => {
          commit("set_taxsList", res.data.MyTaxes);
        })
        .catch((err) => {
          err.message;
        });
    },
    set_autoSelectedTax: (state, data) => {
      state.autoSelectedTax = data;
    },

    set_addingTax: (state, data) => {
      state.addingTax = data;
    },
    setLocale(state, locale) {
      state.currentLocale = locale;
      i18n.locale = locale;
    },
  },
  actions: {
    openAddTaxPopup({ dispatch }) {
      const addTaxModal = document.getElementById("addTaxModal");
       if(addTaxModal){
        addTaxModal.style.display = "flex";

      }
      dispatch("getTaxsCategory");
    },

    closeAddTaxPopup() {
      const addTaxModal = document.getElementById("addTaxModal");
       if(addTaxModal){
        addTaxModal.style.display = "none";

      }
    },

    getTaxsAction: ({ commit }) => {
      AXIOS.get("Tax/GetTax")
        .then((res) => {
          commit("setTaxes", res.data.Tax);
        })
        .catch((err) => {
          err.message;
        });
    },
    getTaxsCategory: ({ commit }) => {
      AXIOS.get("api/GetTaxCategories")
        .then((res) => {
          commit("taxCategories", res.data);
        })
        .catch((err) => {
          err.message;
        });
    },

    addTaxsAction: ({ commit, dispatch, state }, payload) => {
      return AXIOS.post("Tax/AddTax", {
        tax_name: payload.tax_name,
        tax_precntage: payload.tax_precntage,
        tax_category_id: payload.tax_category_id,
        sub_tax_category_id: payload.sub_tax_category_id, // Pass the selected sub-tax ID


      })
        .then((res) => {
          console.log(res,"______>")

          if (res.status == 200) {
            console.log(arabicLocale.isArabicLocale, "arabic");
            successToast("Submitted successfully!");
            dispatch("get_taxsList_action");
            setTimeout(() => {
              commit(
                "set_autoSelectedTax",
                state.taxsList[state.taxsList.length - 1]
              );
            }, 1000);
            commit("set_addingTax", false);

            dispatch("closeAddTaxPopup");
          }
          return res;
        })
        .catch((err) => {
          console.log(err,"______>")
          err.message;
        })
        .finally(() => {
          commit("set_addingTax", false);
        });
    },

    // accountTypeAction: async ({commit}) => {
    //     try {
    //         const res = await AXIOS.get(`COA/GetMainChart`)
    //         commit('setCoa', res.data.MainChart)
    //     } catch (error) {

    //     }
    // },

    getTaxTypesAction: ({ commit }) => {
      AXIOS.get("Tax/GetTaxType")
        .then((res) => {
          commit("setTaxTypes", res.data.MyExpenses);
        })
        .catch((err) => {});
    },

    get_taxsList_action: ({ commit }) => {
      AXIOS.get("Forms/GetMyTaxesList")
        .then((res) => {
          commit("set_taxsList", res.data.MyTaxes);
        })
        .catch((err) => {
          err.message;
        });
    },

    get_new_taxsList: ({ commit }) => {
      AXIOS.get("Forms/taxes")
        .then((res) => {
          commit("set_new_taxsList", res.data.Taxes);
        })
        .catch((err) => {
          err.message;
        });
    },
  },
  modules: {},
};
