import CryptoJS from "crypto-js";
import app from "@/env";

const SECRET_KEY = app.VUE_APP_SECRET_KEY;

function encrypt(value) {
  return CryptoJS.AES.encrypt(value, SECRET_KEY).toString();
}

function decrypt(encryptedValue) {
  const bytes = CryptoJS.AES.decrypt(encryptedValue, SECRET_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
}

function getCookies(name) {
  const cookieMatch = document.cookie.match(new RegExp(`${name}=([^;]+)`));

  if (cookieMatch !== null) {
    const encryptedValue = JSON.parse(cookieMatch[1]);
    return decrypt(encryptedValue);
  }

  return null;
}

function deleteCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/`;
}

function storeCookies(name, value) {
  const ciphertext = encrypt(value);
  const cookie = `${name}=${JSON.stringify(ciphertext)}; path=/`;

  document.cookie = cookie;
}

export { getCookies, storeCookies, deleteCookie };
